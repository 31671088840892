import { PublisherData } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { CardBody, CardBodyProps } from '../Card';
import { EventCardActions, EventCardActionsProps } from './EventCardActions';
import {
  EventCardDescription,
  EventCardDescriptionProps,
} from './EventCardDescription';
import {
  EventCardEventInfo,
  EventCardEventInfoProps,
} from './EventCardEventInfo';
import { EventCardTags } from './EventCardTags';
import { EventCardTitle, EventCardTitleProps } from './EventCardTitle';

export interface EventCardBodyProps extends CardBodyProps {
  descriptionProps?: EventCardDescriptionProps;
  disableClamp?: boolean;
  hideTags?: boolean;
  infoProps?: EventCardEventInfoProps;
  titleProps?: EventCardTitleProps;
  publisherData?: PublisherData;
  detailsUrl?: string;
  actionsProps?: EventCardActionsProps;
}

export const EventCardBody = ({
  children,
  descriptionProps,
  hideTags,
  infoProps,
  titleProps,
  actionsProps,
  ...props
}: EventCardBodyProps) => (
  <CardBody data-testid="event-card-body" {...props}>
    <EventCardEventInfo {...infoProps} />
    <EventCardTitle {...titleProps} />
    <EventCardDescription {...descriptionProps} />
    {actionsProps && <EventCardActions {...actionsProps} />}
    {children}
    {!hideTags && <EventCardTags />}
  </CardBody>
);
