import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  ModalHeader,
} from '../Modal';
import { useEventCategories } from 'src/shared/context';
import { Flex, Box } from '@amzn/ring-ui-react-components';
import { CategoryProps } from './IncidentCategories';
import { useIsDesktop } from 'src/shared/hooks';
import { useEffect, useRef } from 'react';
import { useNeighborsWebViewEventStreamDispatcher } from 'src/components/App/hooks';
import { useAuth } from 'src/context';
import { useTranslation } from 'react-i18next';

const IncidentCategories = () => {
  const { categories } = useEventCategories();

  return (
    <Flex flexDirection="column">
      {categories.map((category) => (
        <Category key={category.id} category={category} />
      ))}
    </Flex>
  );
};

const Category = (props: CategoryProps) => {
  const {
    category: { title, text },
  } = props;

  return (
    <Flex flexDirection="column" p="4">
      <Flex alignItems="center">{title}</Flex>
      <Box alignItems="center" fontWeight="200" fontSize="14px">
        {text}
      </Box>
    </Flex>
  );
};

export interface IncidentCategoriesModalProps extends ModalProps {}

export const IncidentCategoriesModal = (
  props: IncidentCategoriesModalProps,
) => {
  const isDesktop = useIsDesktop();
  const pageViewRef = useRef(false);
  const dispatchView = useNeighborsWebViewEventStreamDispatcher(
    'nhWeb_postCategoryDefinitions',
  );
  const { isPending } = useAuth();

  const { t } = useTranslation('components.event');

  useEffect(() => {
    if (
      pageViewRef.current === true ||
      isPending === true ||
      props.isOpen === false
    )
      return;

    dispatchView();
    pageViewRef.current = true;
  }, [dispatchView, isPending, props.isOpen]);

  return (
    <Modal {...props} isCentered size="lg" blockScrollOnMount={isDesktop}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader py="4" flexDirection="column" alignItems="center">
            {t('dropdown.category-definitions')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={4} webkitOverflowScrolling={'touch'}>
            <IncidentCategories />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
