import { matchPath } from 'react-router-dom';
import { AppRoutes } from 'src/routes';
import { useEventStream } from 'src/shared/hooks/useEventStream';
import { ViewType } from 'src/components/App/hooks';

export const getViewFromRoutePath: (path: string) => undefined | ViewType = (
  path,
) => {
  const match = matchPath(path.replace('wv/', ''), {
    path: [AppRoutes.Feed, AppRoutes.MyPosts, AppRoutes.EventDetailOrSharePage],
    exact: true,
    strict: true,
  });

  if (match === null) return undefined;

  switch (match.path) {
    case AppRoutes.Feed:
      return 'nhWeb_mainFeed';
    case AppRoutes.MyPosts:
      return 'nhWeb_myPosts';
    case AppRoutes.EventDetailOrSharePage:
      return 'nhWeb_postDetail';
    default:
      return undefined;
  }
};

export const useNeighborsWebViewEventStreamDispatcher = (view: ViewType) => {
  const { dispatchEvent } = useEventStream();

  return async (tags: string[] = [], properties: {} = {}) =>
    await dispatchEvent('Screen.View', tags, {
      title: view,
      ...properties,
    });
};
