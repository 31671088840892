import {
  Box,
  Flex,
  IconButton,
  InfoOutline,
  Switch,
} from '@amzn/ring-ui-react-components';
import { HelperText } from '../HelperText';
import { useIsDesktop } from 'src/shared/hooks';
import { useEventCardState } from './context';

export type EventCardIncidentStatusProps = {
  isResolveLoading: boolean;
  onClick?: () => void;
};

export const EventCardIncidentStatus = ({
  onClick,
}: EventCardIncidentStatusProps) => {
  const {
    event: { is_resolved },
  } = useEventCardState();
  const isDesktop = useIsDesktop();

  const label = (
    <>
      Updating your incident status to "resolved" allows
      <br />
      you to inform your Neighbors when there has been a<br />
      resolution to your incident. If there's no resolution,
      <br />
      keep this marked as unresolved.
    </>
  );

  return (
    <>
      <Flex
        borderTopWidth={1}
        py={2}
        px={{ base: 6, m: 8 }}
        align="center"
        justify="space-between"
      >
        <HelperText
          label={label}
          data-offset={
            isDesktop ? "{'top': 0, 'left': 0}" : "{'top': 0, 'left': -70}"
          }
          cursor="pointer"
        >
          <Flex align="center">
            <Box textStyle="body-normal-medium">Incident Status</Box>
            <IconButton
              aria-label="Info"
              height="auto"
              w="auto"
              variant="unstyled"
              icon={<InfoOutline boxSize={6} color="primary.500" />}
              _focus={{ border: 'none' }}
            />
          </Flex>
        </HelperText>

        <Flex align="center">
          <Box as="span" mr={2} textStyle="body-normal-light">
            {is_resolved ? 'Resolved' : 'Unresolved'}
          </Box>
          <Switch
            isChecked={is_resolved}
            size="lg"
            colorScheme="primary"
            onChange={onClick}
          />
        </Flex>
      </Flex>
    </>
  );
};
