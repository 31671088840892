import { forwardRef } from 'react';
import { isLocalBuild } from 'src/utils';
import { BoxProps } from '../Box';
import { Flex } from '../Flex';
import { useModalContext } from './ModalContext';

type ModalOverlayProps = Omit<
  BoxProps,
  | 'bg'
  | 'justifyContent'
  | 'alignItems'
  | 'overflow'
  | 'position'
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'zIndex'
>;

export const ModalOverlay = forwardRef<HTMLDivElement, ModalOverlayProps>(
  (props, ref) => {
    const { getOverlayProps, scrollBehavior, isCentered } = useModalContext();
    const overlayProps = getOverlayProps({ ...props, ref });

    return (
      <Flex
        {...overlayProps}
        bg="blackAlpha.500"
        justifyContent="center"
        alignItems={isCentered ? 'center' : 'flex-start'}
        overflow={scrollBehavior === 'inside' ? 'hidden' : 'auto'}
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        zIndex="modal"
      />
    );
  },
);

if (isLocalBuild) {
  ModalOverlay.displayName = 'ModalOverlay';
}
