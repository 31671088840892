import { forwardRef } from 'react';
import { Theme } from 'src/shared/theme';

import { BoxProps } from '../Box';
import { Flex } from '../Flex';
import { ModalProps } from './Modal';
import { useModalContext } from './ModalContext';

export const ModalContent = forwardRef<HTMLDivElement, BoxProps>(
  (props, ref) => {
    const { getContentProps, size, scrollBehavior } = useModalContext();

    const contentProps = getContentProps({ ...props, ref });

    const maxWidth = { _: '100%', m: getMaxWidthBySize(size) };
    const maxHeight = {
      _: 'none',
      m: scrollBehavior === 'inside' ? 'calc(100vh - 7.5rem)' : 'none',
    };

    return (
      <Flex
        data-testid="modal-content"
        borderRadius={{ _: 'regular', m: 'rounder' }}
        height={{ _: '100%', m: 'auto' }}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        {...contentProps}
        _focus={{ outline: 0 }}
        as="section"
        bg="white"
        color="inherit"
        flexDirection="column"
        overflow="hidden"
        position={{ _: 'fixed', m: 'relative' }}
        width="100%"
        sx={(theme: Theme) => ({
          boxShadow: `0px 8px 12px ${theme.colors.blackAlpha[300]}`,
        })}
        tabIndex={0}
      />
    );
  },
);

ModalContent.displayName = 'ModalContent';

const getMaxWidthBySize = (size: ModalProps['size']) => {
  switch (size) {
    case 'sm':
      return '20rem';
    case 'md':
      return '28rem';
    case 'lg':
      return '32rem';
    case 'xlg':
      return '42rem';
    case 'full':
      return '100%';
    default:
      return 'none';
  }
};
