import { forwardRef } from 'react';

import { Box, BoxBasedComponentProps, BoxProps } from '../Box';
import { FormControlContextValue, useFormControl } from './FormControlContext';

type FormLabelProps = BoxBasedComponentProps<'label', FormControlContextValue>;

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(
  ({ children, sx, ...props }, ref) => {
    const { disabled, required } = useFormControl(props);

    return (
      <Box
        as="label"
        display="inline-block"
        textStyle="body-small-regular"
        pb={1}
        pr={3}
        verticalAlign="middle"
        {...props}
        sx={[
          sx,
          {
            textAlign: 'left',
            opacity: disabled ? '0.4' : '1',
          },
        ]}
        ref={ref}
      >
        {children}
        {required && <RequiredIndicator />}
      </Box>
    );
  },
);

FormLabel.displayName = 'FormLabel';

export const RequiredIndicator = ({
  children = '*',
  ...props
}: Omit<BoxProps, 'aria-hidden'>) => (
  <Box as="span" ml={1} color="negative.500" {...props} aria-hidden>
    {children}
  </Box>
);
