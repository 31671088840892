import { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { AuthContextValue, useAuth } from 'src/context/AuthContext';
import { CenteredLoading } from 'src/shared/components/Loading';
import { goToLogin, isLocalBuild, reLoginWebview } from 'src/utils';
import { useHasWebViewUserAgent } from 'src/hooks';

interface PrivateBaseRouteProps
  extends RouteProps,
    Pick<AuthContextValue, 'isAuthenticated'> {}

const PrivateBaseRoute = ({
  component,
  isAuthenticated,
  ...props
}: PrivateBaseRouteProps) => (
  <Route {...props} component={isAuthenticated ? component : CenteredLoading} />
);

const PrivateDevelopmentRoute = (props: RouteProps) => {
  const { isAuthenticated, isUnauthenticated } = useAuth();
  const isWebview = useHasWebViewUserAgent();

  useEffect(() => {
    if (isUnauthenticated) {
      if (isWebview) {
        reLoginWebview();
      } else {
        goToLogin();
      }
    }
  }, [isUnauthenticated, isWebview]);

  return <PrivateBaseRoute {...props} isAuthenticated={isAuthenticated} />;
};

const PrivateProductionRoute = (props: RouteProps) => {
  const { isAuthenticated } = useAuth();

  return <PrivateBaseRoute {...props} isAuthenticated={isAuthenticated} />;
};

export const PrivateRoute = isLocalBuild
  ? PrivateDevelopmentRoute
  : PrivateProductionRoute;
