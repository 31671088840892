import { memo } from 'react';
import { useToggle } from 'react-use';
import { useAlertAreaState } from 'src/context';
import {
  FeedEventCard,
  FeedEventCardProps,
} from 'src/shared/components/FeedEventCard';
import { EventReportModal } from '../EventCard';
import { useFeedEventCardHandlers } from './hooks';
import { getFeedCardId } from './utils';
import { ItemType } from 'src/components/App/hooks';
import { DeniedPostPage } from '@amzn/ring-neighbors-contentful-gql-schema';

export type FeedEventCardContainerProps = Pick<
  FeedEventCardProps,
  'event' | 'isActive' | 'showDeviceBanner'
> & {
  dispatchEventStreamClick?: (item: ItemType, tags?: string[]) => Promise<void>;
  handleResolvePostClick?: () => void;
  handleReSchedulePostClick?: (id: string, scheduledFor: string) => void;
  handleEditScheduledPostClick?: (
    id: string,
    title: string,
    description: string,
  ) => void;
  denialReasons?: DeniedPostPage[];
};

const FeedEventCardContainer = ({
  event,
  isActive,
  dispatchEventStreamClick,
  handleResolvePostClick,
  handleReSchedulePostClick,
  handleEditScheduledPostClick,
  showDeviceBanner,
  denialReasons,
}: FeedEventCardContainerProps) => {
  const { id: eventId, details } = event;

  const { selectedAlertAreaCenter } = useAlertAreaState();

  const [isReportModalOpen, toggleReportModalIsOpen] = useToggle(false);
  const [show, toggleShow] = useToggle(true);

  const {
    handlers: { onReport, ...handlers },
    states,
  } = useFeedEventCardHandlers({
    eventId,
    isScheduledEvent: details?.category === 'scheduled',
    onMutationCompleted: toggleShow,
    publisherId: details?.publisher_data?.publisher_id as string,
  });

  if (!show) {
    return null;
  }

  const id = getFeedCardId(eventId);

  return (
    <>
      <FeedEventCard
        {...handlers}
        {...states}
        event={event}
        isActive={isActive}
        eventAreaCenter={selectedAlertAreaCenter}
        openReportModal={toggleReportModalIsOpen}
        data-testid="event-feed-card"
        id={id}
        dispatchEventStreamClick={dispatchEventStreamClick}
        handleResolvePostClick={handleResolvePostClick}
        handleReSchedulePostClick={handleReSchedulePostClick}
        handleEditScheduledPostClick={handleEditScheduledPostClick}
        showDeviceBanner={showDeviceBanner}
        denialReasons={denialReasons}
      />
      <EventReportModal
        isOpen={isReportModalOpen}
        onClose={toggleReportModalIsOpen}
        onSubmit={onReport}
      />
    </>
  );
};

const MemoizedFeedEventCardContainer = memo(
  FeedEventCardContainer,
  (prevProps, nextProps) =>
    prevProps.event === nextProps.event &&
    prevProps.isActive === nextProps.isActive,
);

export { MemoizedFeedEventCardContainer as FeedEventCardContainer };
