import type { EventsEdge } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import {
  equalsDefaultCoordsTuple,
  getCoordinatesTuple,
} from 'src/shared/utils';

export const getFeedCardId = (id: string) => `event-${id}-feed-card`;

export const getEventsWithValidLocations = (events: EventsEdge[]) =>
  events.filter(({ node }) => {
    const { details } = node!;

    const isRegional = details?.is_regional;

    if (isRegional) {
      return false;
    }

    const coordinatesTuple = getCoordinatesTuple(details?.location);

    return !equalsDefaultCoordsTuple(coordinatesTuple);
  });
