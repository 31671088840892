import { createContext } from '@chakra-ui/react-utils';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import type { PostingContext } from 'src/components/Posting';
import { sleep } from 'src/shared/utils';
import { useCreateEvent, UseCreateEventReturn } from './hooks/useCreateEvent';

export type CreateEventStateContextValue =
  | (UseCreateEventReturn[1] & { totalCount: number })
  | undefined;

type PostingContextValue = PostingContext | undefined;

export type CreateEventDispatchContextValue = Dispatch<
  SetStateAction<PostingContextValue>
>;

const [CreateEventStateProvider, useCreateEventState] =
  createContext<CreateEventStateContextValue>({ strict: false });

const [CreateEventDispatchProvider, useCreateEventDispatch] =
  createContext<CreateEventDispatchContextValue>();

interface CreateEventProviderProps {
  children: ReactNode;
}

export const CreateEventProvider = ({ children }: CreateEventProviderProps) => {
  const [context, setContext] = useState<PostingContextValue>();
  const [createEvent, state, reset] = useCreateEvent();

  const value = context
    ? { ...state, totalCount: context.media.length }
    : undefined;

  useEffect(() => {
    if (!context) {
      return;
    }

    const request = async () => {
      await createEvent(context);
      await sleep(10000);
      setContext(undefined);
      reset();
    };

    request();
  }, [context, createEvent, reset]);

  return (
    <CreateEventStateProvider value={value}>
      <CreateEventDispatchProvider value={setContext}>
        {children}
      </CreateEventDispatchProvider>
    </CreateEventStateProvider>
  );
};

export { useCreateEventState, useCreateEventDispatch };
