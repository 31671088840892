import { StrictMode } from 'react';
import { render } from 'react-dom';
import { App, AppProviders } from './components/App';
import { reportWebVitals } from './reportWebVitals';
import '@amzn/ring-safe-web/styles/font.global.css';
import '@amzn/ring-safe-web/styles/color.global.css';
import './i18n';
import './fonts.css';
import { BugsnagErrorBoundary } from './BugsnagErrorBoundary';

render(
  <StrictMode>
    <BugsnagErrorBoundary>
      <AppProviders>
        <App />
      </AppProviders>
    </BugsnagErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
