import type {
  Event,
  EventDetails,
  EventResolutionInformation,
  EventUser,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

import { equals } from 'ramda';
import { useMemo } from 'react';
import {
  Check,
  Delete,
  InfoOutline,
  Report,
  Reset,
  ViewOff,
} from '@amzn/ring-ui-react-components';
import { useHeap } from 'src/hooks';
import { DropdownItem } from 'src/shared/components/Dropdown';
import { useFeature } from 'src/context';
import { useTranslation } from 'react-i18next';
import {
  CalendarTimelineDefaultIcon,
  DeleteIcon,
  EditIcon,
} from '@amzn/ring-safe-web';
import { useIsNpssUser } from "src/auth";
import { useIsSameAgency } from "src/shared/hooks";

export interface UseEventDropdownOptionsProps
  extends Pick<Event, 'is_owned'>,
  Pick<EventDetails, 'category'>,
  Pick<EventUser, 'agency_id'>,
  Partial<Pick<EventResolutionInformation, 'is_resolved'>> {
  onClickCategoryDefinitions: () => void;
  onClickHide: () => void;
  onClickDelete?: () => void;
  onClickReport: () => void;
  onClickResolve: () => void;
  onClickReSchedule?: () => void;
  onClickEditScheduledPost?: () => void;
}

// TODO: deprecate this hook
export const useEventDropdownOptions = ({
  is_owned,
  is_resolved,
  category,
  agency_id,
  onClickCategoryDefinitions,
  onClickHide,
  onClickDelete,
  onClickReport,
  onClickResolve,
  onClickReSchedule,
  onClickEditScheduledPost,
}: UseEventDropdownOptionsProps) => {
  const { t } = useTranslation('components.event');
  const { track } = useHeap();
  const enableResolveSwitch = useFeature('ENABLE_RESOLVE_SWITCH');
  const isNpssScheduledPost = equalsNpssScheduledPostCategory(category);
  const isNpssUser = useIsNpssUser();
  const isSameAgency = useIsSameAgency(agency_id);

  const options = useMemo(() => {
    const handleHide = () => {
      onClickHide?.();

      track('Hide Post Click');
    };

    const handleReport = () => {
      onClickReport?.();

      track('Report Post Click');
    };

    const BaseDropdownOptions = [
      <DropdownItem
        icon={<InfoOutline mr={2} color="neutral.500" boxSize={8} />}
        key="categoryDefinitions"
        onClick={onClickCategoryDefinitions}
      >
        {t('dropdown.category-definitions')}
      </DropdownItem>,
    ];

    const ResolvePostDropdownOptions =
      is_resolved === true ? (
        <DropdownItem
          icon={<Reset mr={2} color="neutral.500" boxSize={8} />}
          key="unresolve"
          onClick={onClickResolve}
        >
          {t('dropdown.unresolve')}
        </DropdownItem>
      ) : (
        <DropdownItem
          icon={<Check mr={2} color="neutral.500" boxSize={8} />}
          key="resolve"
          onClick={onClickResolve}
        >
          {t('dropdown.resolve')}
        </DropdownItem>
      );

    if (is_owned || isSameAgency) {
      if (isNpssScheduledPost) {
        return [
          <DropdownItem
            icon={
              <CalendarTimelineDefaultIcon
                width={32}
                style={{ marginRight: 'var(--ring-space-2)' }}
              />
            }
            key={'re-schedule'}
            onClick={onClickReSchedule}
          >
            {t('dropdown.reschedule')}
          </DropdownItem>,
          <DropdownItem
            icon={
              <EditIcon
                width={32}
                style={{ marginRight: 'var(--ring-space-2)' }}
              />
            }
            onClick={onClickEditScheduledPost}
            key={'edit-scheduled-post'}
          >
            {t('dropdown.edit-description')}
          </DropdownItem>,
          <DropdownItem
            icon={
              <DeleteIcon
                width={32}
                fill={'var(--negative-600)'}
                style={{ marginRight: 'var(--ring-space-2)' }}
              />
            }
            destructive={true}
            key="delete-scheduled"
            onClick={onClickDelete}
          >
            {t('dropdown.delete')}
          </DropdownItem>,
        ];
      }
      return [
        enableResolveSwitch ? ResolvePostDropdownOptions : <></>,
        ...(!isNpssUser? BaseDropdownOptions:[]),
        !is_owned? (
            <></>
        ) : (<DropdownItem
          icon={
            <Delete mr={2} boxSize={8} color="negative.500" name="delete" />
          }
          destructive={true}
          key="delete"
          onClick={onClickDelete}
        >
          {t('dropdown.delete')}
        </DropdownItem>),
      ];
    }

    const isPublicAssistance = equalsPublicAssistanceCategory(category);

    const HidePostOptions = [
      <DropdownItem
        icon={<ViewOff mr={2} boxSize={8} color="negative.500" />}
        key="hide"
        destructive={true}
        onClick={handleHide}
      >
        {t('dropdown.hide')}
      </DropdownItem>,
    ];

    const ReportOptions = !isPublicAssistance
      ? [
          <DropdownItem
            icon={<Report mr={2} color="neutral.500" boxSize={8} />}
            key="report"
            onClick={handleReport}
          >
            {t('dropdown.report')}
          </DropdownItem>,
        ]
      : [];

    return [...BaseDropdownOptions, ...ReportOptions, ...HidePostOptions];
  }, [
    category,
    is_owned,
    is_resolved,
    onClickCategoryDefinitions,
    onClickDelete,
    onClickHide,
    onClickReport,
    onClickResolve,
    track,
    enableResolveSwitch,
    t,
    isNpssScheduledPost,
    onClickReSchedule,
    onClickEditScheduledPost,
    isSameAgency,
    isNpssUser,
  ]);

  return options;
};

const equalsPublicAssistanceCategory = equals('publicassistance');

const equalsNpssScheduledPostCategory = equals('scheduled');
