import { pipe } from 'ramda';
import create, { State, StateCreator, UseStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  NpssAccount,
  NpssAbility,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

export enum AuthStatus {
  pending = 'pending',
  authenticated = 'authenticated',
  unauthenticated = 'unauthenticated',
}

type CreateStore = <T extends State>(
  stateCreator: StateCreator<T>,
  prefix: string,
) => UseStore<T>;

const createStore = pipe(devtools, create) as CreateStore;

const useAuthStore = createStore<{
  authStatus: AuthStatus;
  isNpssUser: boolean;
  npssUser: NpssAccount | null;
  userID: string;
  userIdHmacToken: string;
}>(
  () => ({
    authStatus: AuthStatus.pending,
    isNpssUser: false,
    npssUser: null,
    userID: 'userID not fetched yet',
    userIdHmacToken: 'token not fetched yet',
  }),
  'AuthStore',
);

export const getAuthStatus = () => useAuthStore.getState().authStatus;
export const getIsNpssUser = () => useAuthStore.getState().isNpssUser;
export const getUserID = () => useAuthStore.getState().userID;
export const getUserIDHmacToken = () => useAuthStore.getState().userIdHmacToken;

export const setAuthStatus = (newStatus: AuthStatus) => {
  useAuthStore.setState({ authStatus: newStatus });
};

export const setIsNpssUser = (newIsNpssUser: boolean) => {
  useAuthStore.setState({ isNpssUser: newIsNpssUser });
};

export const setUserID = (newUserID: string) => {
  useAuthStore.setState({ userID: newUserID });
};

export const setUserIdHmacToken = (userIdHmacToken: string) => {
  useAuthStore.setState({ userIdHmacToken });
};

export const useAuthStatus = () => useAuthStore(({ authStatus }) => authStatus);
export const useIsNpssUser = () => useAuthStore(({ isNpssUser }) => isNpssUser);

export const setNpssUser = (npssUser: NpssAccount | null) => {
  useAuthStore.setState({ npssUser: npssUser });
};

export const getNpssUser = () => useAuthStore.getState().npssUser;

export const canNpssUserPost = () => {
  let canNpssUserPost = false;
  const npssUser: any | null = useAuthStore.getState().npssUser;

  if (npssUser != null) {
    npssUser.abilities.forEach((ability: NpssAbility) => {
      if (ability.name === 'nhfeed_post') {
        canNpssUserPost = ability.enabled;
      }
    });
  }

  return canNpssUserPost;
};
