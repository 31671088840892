import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getEventDetailPath } from 'src/routes';
import { createClickKeyDownHandler } from 'src/shared/utils';
import {
  EventCardBody,
  EventCardBodyProps,
  EventCardDescriptionProps,
  useEventCardState,
} from '../EventCard';
import { Link } from '../Link';

type FeedEventCardBodyProps = Omit<
  EventCardBodyProps,
  'px' | 'titleProps' | 'descriptionProps'
>;

export const FeedEventCardBody = (props: FeedEventCardBodyProps) => {
  const { t } = useTranslation('components.event');
  const {
    event: { id, actions, publisher_data },
  } = useEventCardState();

  const handleKeyDown = createClickKeyDownHandler(props.onClick);

  const descriptionProps = useMemo<EventCardDescriptionProps>(() => {
    const eventDetailPath = getEventDetailPath(id);
    const clampLabel =
      props.detailsUrl && props.publisherData?.platform_name ? (
        <Link href={props.detailsUrl} target="_blank" rel="noopener noreferrer">
          {t('body.view-on', {
            platformName: props.publisherData.platform_name,
          })}
        </Link>
      ) : (
        <Link href={eventDetailPath}>Read More</Link>
      );

    return {
      wrapperProps: { mb: 4 },
      noOfLines: props.disableClamp ? undefined : 3,
      more: props.disableClamp ? null : clampLabel,
    };
  }, [
    id,
    props.disableClamp,
    props.detailsUrl,
    props.publisherData?.platform_name,
    t,
  ]);

  const actionsProps = { actions, publisher_id: publisher_data?.publisher_id };

  return (
    <EventCardBody
      {...props}
      descriptionProps={descriptionProps}
      onKeyDown={handleKeyDown}
      p={6}
      titleProps={titleProps}
      actionsProps={actionsProps}
    />
  );
};

const titleProps = {
  textStyle: 'heading-small-medium',
} as const;
