import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Linkify from 'linkify-react';
import {
  Code,
  Divider,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@amzn/ring-ui-react-components';
import { CardText, CardTextProps } from '../Card';
import { useEventCardState } from './context';
import { theme } from 'src/shared/theme';
import { EventCardPetInfo } from './EventCardPetInfo';
import { EventCardVehicleInfo } from './EventCardVehicleInfo';
import { EventCardPersonInfo } from './EventCardPersonInfo';
import { useFeature } from 'src/context';

export type EventCardDescriptionProps = Omit<
  CardTextProps,
  'children' | 'textStyle'
>;

const linkifyOptions = {
  target: '_blank',
  rel: 'noopener noreferrer',
  attributes: {
    style: {
      textDecoration: 'underline',
      color: theme.colors.primary[400],
      fontWeight: theme.fontWeights.medium,
    },
  },
};

const markdownComponents = {
  a: ({ ...props }) => (
    <Link
      href={props.href}
      target={props.href.includes('.ring.com') ? '_self' : '_blank'}
      rel={props.href.includes('.ring.com') ? '' : 'noopener noreferrer'}
      textDecoration="underline"
    >
      {props.children}
    </Link>
  ),
  code: ({ ...props }) => <Code>{props.children}</Code>,
  hr: () => <Divider />,
  p: ({ ...props }) => <Text>{props.children}</Text>,
  li: ({ ...props }) => (
    <ListItem whiteSpace="normal">{props.children}</ListItem>
  ),
  ul: ({ ...props }) => <UnorderedList>{props.children}</UnorderedList>,
  ol: ({ ...props }) => <OrderedList>{props.children}</OrderedList>,
};

export const EventCardDescription = (props: EventCardDescriptionProps) => {
  const {
    event: { description, pet_info, person_descriptions, vehicle_descriptions },
  } = useEventCardState();
  const isMarkdownEnabled = useFeature('ENABLE_MARKDOWN');

  if (!description) {
    return null;
  }

  return (
    <CardText
      data-testid="event-card-description"
      {...props}
      textStyle="body-normal-light"
      whiteSpace="pre-wrap"
    >
      {isMarkdownEnabled ? (
        <ReactMarkdown
          skipHtml
          linkTarget="_blank"
          remarkPlugins={[remarkGfm]}
          components={markdownComponents}
        >
          {description}
        </ReactMarkdown>
      ) : (
        <Linkify options={linkifyOptions}> {description} </Linkify>
      )}
      {pet_info && <EventCardPetInfo petInfo={pet_info} />}
      {person_descriptions &&
        person_descriptions.map((person_description, index, array) => (
          <EventCardPersonInfo
            personDescription={person_description}
            index={array.length === 1 ? 0 : index + 1}
            key={person_description.trait}
          />
        ))}
      {vehicle_descriptions &&
        vehicle_descriptions.map((vehicle_descripition, index, array) => (
          <EventCardVehicleInfo
            vehicleDescription={vehicle_descripition}
            index={array.length === 1 ? 0 : index + 1}
            key={vehicle_descripition.license_plate_info}
          />
        ))}
    </CardText>
  );
};
