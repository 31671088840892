import { forwardRef } from 'react';

import { Box, BoxBasedComponentProps } from '../Box';
import { Flex } from '../Flex';
import { Icon, IconName } from '../Icon';
import { FormControlContextValue, useFormControl } from './FormControlContext';

interface FormErrorMessageOwnProps extends FormControlContextValue {
  icon?: IconName;
}

type FormErrorMessageProps = BoxBasedComponentProps<
  'div',
  FormErrorMessageOwnProps
>;

export const FormErrorMessage = forwardRef<HTMLElement, FormErrorMessageProps>(
  ({ id, children, icon = 'warning', ...props }, ref) => {
    const { invalid } = useFormControl(props);

    if (!invalid || !children) return null;

    return (
      <Flex
        alignItems="center"
        color="negative.500"
        fontSize={1}
        id={id ? `${id}-error-message` : undefined}
        mt={2}
        {...props}
        ref={ref}
      >
        <Icon name={icon} mr={1} aria-hidden />
        <Box lineHeight="normal">{children}</Box>
      </Flex>
    );
  },
);
