import { ReactNode } from 'react';
import { Box, BoxProps } from '@amzn/ring-ui-react-components';

interface CapitalizerProps extends BoxProps {
  children: ReactNode;
}

export const Capitalizer = ({ children, ...props }: CapitalizerProps) => (
  <Box {...props} textTransform="capitalize">
    {children}
  </Box>
);
