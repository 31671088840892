import { useIsMobile } from 'src/shared/hooks';

import { NpssNavBarMobile } from 'src/components/Navbar/NpssNavBarMobile';
import { NpssNavBarDesktop } from 'src/components/Navbar/NpssNavBarDesktop';
import { useDisclosure } from '@amzn/ring-ui-react-components';
import { SettingsModal } from 'src/shared/components/SettingsModal';

export const NpssNavBar = () => {
  const isMobile = useIsMobile();

  const { isOpen, onClose } = useDisclosure();

  return (
    <>
      {isMobile ? <NpssNavBarMobile /> : <NpssNavBarDesktop />}
      <SettingsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
