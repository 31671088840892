import NPSS_USER_SCHEDULED_POSTS from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/npss/npssUserScheduledPosts.graphql';
import { useIsNpssUser } from 'src/auth';
import { useQuery } from '@apollo/client';
import {
  NpssScheduledPost,
  NpssUserScheduledPostsQuery,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

export const useGetNpssScheduledPosts = () => {
  const isNpssUser = useIsNpssUser();
  const { data, ...rest } = useQuery<NpssUserScheduledPostsQuery>(
    NPSS_USER_SCHEDULED_POSTS,
    {
      skip: !isNpssUser,
    },
  );

  return {
    ...rest,
    data: (data?.npssUserScheduledPosts ??
      []) as unknown as NpssScheduledPost[],
  };
};
