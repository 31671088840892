import { SafeProvider } from '@amzn/ring-ui-react-components';
import { ApolloProvider } from '@apollo/client';
import type { ReactNode } from 'react';
import { Router } from 'react-router-dom';
import { client } from 'src/apollo';
import { AuthProvider, CreateEventProvider } from 'src/context';
import { history } from 'src/history';
import { ToastContainer } from 'src/shared/components/Toast';
import { ThemeProvider } from 'src/shared/context';
import { GlobalStyle } from 'src/shared/global';
import { theme } from 'src/shared/theme';
import { theme as ringSafeTheme } from 'src/theme';

interface ProvidersProps {
  children: ReactNode;
}

export const AppProviders = ({ children }: ProvidersProps) => (
  <Router history={history}>
    <ApolloProvider client={client}>
      <SafeProvider theme={ringSafeTheme}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ToastContainer limit={1} />
          <AuthProvider>
            <CreateEventProvider>{children}</CreateEventProvider>
          </AuthProvider>
        </ThemeProvider>
      </SafeProvider>
    </ApolloProvider>
  </Router>
);
