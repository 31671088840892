import { isLocalBuild } from 'src/utils';

import { Box, BoxBasedComponentProps } from '../Box';
import { DropdownProvider } from './DropdownContext';
import { useDropdown, UseDropdownProps } from './useDropdown';

export type DropdownProps = BoxBasedComponentProps<'div'> & UseDropdownProps;

export const Dropdown = ({
  closeOnSelect,
  isOpen,
  onClose,
  openOnMouseEnter,
  ...props
}: DropdownProps) => {
  const context = useDropdown({
    closeOnSelect,
    isOpen,
    onClose,
    openOnMouseEnter,
  });

  const onMouseLeave = context.openOnMouseEnter ? context.onClose : undefined;

  return (
    <DropdownProvider value={context}>
      <Box
        {...props}
        onMouseLeave={onMouseLeave}
        position="relative"
        data-testid="dropdown"
      />
    </DropdownProvider>
  );
};

if (isLocalBuild) {
  Dropdown.displayName = 'Dropdown';
}
