import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  navbarHeight,
  navEventKey,
  useNavbarContext,
} from '@amzn/ring-ui-react-components';
import { usePushToFeed } from 'src/hooks';
import { NavbarBase, NavMobileButton } from 'src/components/Navbar/NavbarBase';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerMenu,
  NpssDrawerMenuItem,
} from 'src/shared/components/Drawer';
import { npssLogoutUrl } from 'src/utils';
import { getNpssUser } from 'src/auth';
import { config } from 'src/config';
const { NPSS_BASE_URL, NPSS_HELP_URL } = config;

const NpssNavBar = () => {
  const { t } = useTranslation('components.navbar', { useSuspense: false });
  const { activeKey } = useNavbarContext();
  const handlePushToFeed = usePushToFeed();

  const isOpen = navEventKey === activeKey;

  const nppssUser: any | null = getNpssUser();
  const agencyUuid = useMemo(() => {
    if (nppssUser != null) {
      return nppssUser.agency_uuid;
    }
    return '';
  }, [nppssUser]);
  return (
    <>
      <NavMobileButton icon="feed" onClick={handlePushToFeed}>
        {t('feed')}
      </NavMobileButton>
      <Drawer isOpen={isOpen} top={navbarHeight}>
        <DrawerBody>
          <DrawerMenu title={''}>
            <NpssDrawerMenuItem
              icon="post_neighborhoods"
              href={'/settings/my-posts'}
              as="a"
            >
              {t('my-posts')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="report"
              href={NPSS_BASE_URL + '/agency-reports'}
              as="a"
            >
              {t('report')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="agency"
              href={NPSS_BASE_URL + '/agencies/' + agencyUuid}
              as="a"
            >
              {t('profile')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="question_circle"
              href={NPSS_HELP_URL}
              as="a"
            >
              {t('help')}
            </NpssDrawerMenuItem>
          </DrawerMenu>
          <DrawerMenu title="Settings">
            <NpssDrawerMenuItem
              icon="account-mgmt"
              href={NPSS_BASE_URL + '/profile'}
              as="a"
            >
              {t('account-settings')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="email_settings"
              href={'/settings/email'}
              as="a"
            >
              {t('email-settings')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="geofencing"
              href={NPSS_BASE_URL + '/assigned_areas'}
              as="a"
            >
              {t('assigned-areas')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="shared_user"
              href={NPSS_BASE_URL + '/alerts/my/published'}
              as="a"
            >
              {t('manage-users')}
            </NpssDrawerMenuItem>
          </DrawerMenu>
        </DrawerBody>
        <DrawerFooter>
          <DrawerMenu>
            <NpssDrawerMenuItem as="a" href={npssLogoutUrl} icon="sign-out">
              {t('sign-out')}
            </NpssDrawerMenuItem>
          </DrawerMenu>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

export const NpssNavBarMobile = () => (
  <NavbarBase>
    <NpssNavBar />
  </NavbarBase>
);
