import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@amzn/ring-ui-react-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Image } from '@amzn/ring-safe-web';
import { DeniedPostFaqDescription } from '@amzn/ring-neighbors-contentful-gql-schema';
import { useEffect, useRef } from 'react';

interface DeniedPostFaqModalProps {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  iconUrl: string;
  title: string;
  subtitle: string;
  richTextContent?: DeniedPostFaqDescription['json'];
  onOpen?: (...args: any[]) => void;
}

const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_: any, children: any) => (
      <Box as="ul" pl={10}>
        {children}
      </Box>
    ),
    [BLOCKS.LIST_ITEM]: (_: any, children: any) => (
      <Box as="li">{children}</Box>
    ),
    [BLOCKS.PARAGRAPH]: (_: any, children: any) => (
      <Box as="p" display="block" mb="1rem">
        {children}
      </Box>
    ),
  },
};

export const DeniedPostFaqModal = (props: DeniedPostFaqModalProps) => {
  const {
    isOpen,
    onClose,
    header,
    iconUrl,
    title,
    subtitle,
    richTextContent,
    onOpen,
  } = props;

  const pageViewRef = useRef<boolean>(false);
  useEffect(() => {
    if (pageViewRef.current) return;

    if (props.isOpen === true && onOpen) {
      onOpen();
      pageViewRef.current = true;
    }
  }, [props.isOpen, onOpen]);

  return (
    <Modal
      scrollBehavior="inside"
      blockScrollOnMount={false}
      data-testid="denied-post-faq-modal"
      onClose={onClose}
      size="full"
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" boxShadow="md" zIndex={2}>
          <Box
            textStyle={{
              base: 'body-large-medium',
            }}
          >
            {header}
          </Box>
        </ModalHeader>
        <ModalCloseButton
          data-testid="denied-post-faq-modal-close"
          color="neutral.500"
          zIndex={3}
        />
        <ModalBody
          data-testid="denied-post-faq-modal-body"
          display="flex"
          flexDirection={{ base: 'column', m: 'row' }}
          p={0}
        >
          <Flex boxShadow="md" p={6} zIndex={1} flexDirection="column">
            <Box mb={2}>
              <Image width="36px" src={iconUrl} alt="FAQ icon" />
            </Box>
            <Box
              mb={4}
              textStyle={{
                base: 'heading-small-medium',
                m: 'heading-normal-medium',
              }}
            >
              {title}
            </Box>
            <Box
              textStyle={{
                base: 'body-large-light',
              }}
            >
              {subtitle}
            </Box>
            {richTextContent && (
              <Box
                lineHeight="24px"
                textStyle={{
                  base: 'body-large-light',
                }}
              >
                {documentToReactComponents(richTextContent, options)}
              </Box>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
