import {
  PlaybackControls,
  PlaybackRTCClient,
  SignalingClient,
  SignalingLogger,
} from '@amzn/ring-site-webrtc-client';

import { CameraAngle, getIntersystemID } from './utils';

export class PlaybackRTCService {
  private static instance: PlaybackRTCService;

  private constructor() {}

  public static getInstance(): PlaybackRTCService {
    if (!PlaybackRTCService.instance) {
      PlaybackRTCService.instance = new PlaybackRTCService();
    }

    return PlaybackRTCService.instance;
  }

  public getPlaybackRTCClient(
    baseWSSUrl: string,
    startTimestamp: number,
    endTimestamp: number,
    deviceId: number,
  ) {
    //TODO: Implement Client App Services client
    //https://confluence.atl.ring.com/pages/viewpage.action?spaceKey=RS&title=Client+App+Services
    //example: https://code.amazon.com/packages/RingSiteAccountService/blobs/mainline/--/src/services/ring-solutions/ring-solutions-clap-api-client.ts

    const clapApiClient = {} as any;

    const signalingClient = SignalingClient.getInstance(baseWSSUrl, {
      logger: new SignalingLogger(),
      clapApiClient,
    });

    //TODO: Implement logger
    const webRTCLogger = {} as any;

    const playbackRTCClient = new PlaybackRTCClient(signalingClient, {
      initialAngle: CameraAngle.None, //implement camera angle parameter
      startTimestamp,
      endTimestamp,
      iceServers: [],
      deviceId,
      getRIID: () => getIntersystemID(deviceId),
      logger: webRTCLogger,
    });

    const playBackRTCControls = new PlaybackControls(playbackRTCClient);

    return { playbackRTCClient, playBackRTCControls };
  }
}
