import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import type {
  AppearanceProperty,
  BackfaceVisibilityProperty,
  CursorProperty,
  FontWeightProperty,
  ObjectFitProperty,
  OutlineProperty,
  ResizeProperty,
  TextDecorationProperty,
  TextTransformProperty,
  TransformProperty,
  TransitionProperty,
  UserSelectProperty,
  WhiteSpaceProperty,
  WordBreakProperty,
  WordWrapProperty,
  ZIndexProperty,
  WebkitOverflowScrollingProperty,
} from 'csstype';
import type { ResponsiveValue, TextStyle, ZIndices } from 'src/shared/theme';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  buttonStyle,
  ButtonStyleProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  opacity,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  system,
  textStyle,
  typography,
  TypographyProps,
  zIndex,
} from 'styled-system';

interface ZIndexProps {
  zIndex?: ResponsiveValue<ZIndices | ZIndexProperty>;
}
interface WebkitOverflowScrollingProps {
  webkitOverflowScrolling?: ResponsiveValue<WebkitOverflowScrollingProperty>;
}

interface TextStyleProps {
  textStyle?: ResponsiveValue<TextStyle>;
}

interface ExtraProps {
  appearance?: AppearanceProperty;
  backfaceVisibility?: BackfaceVisibilityProperty;
  cursor?: CursorProperty;
  objectFit?: ObjectFitProperty;
  outline?: OutlineProperty<string>;
  resize?: ResizeProperty;
  textDecoration?: TextDecorationProperty<string>;
  textTransform?: TextTransformProperty;
  transform?: TransformProperty;
  transition?: TransitionProperty;
  userSelect?: UserSelectProperty;
  whiteSpace?: WhiteSpaceProperty;
  wordBreak?: WordBreakProperty;
  wordWrap?: WordWrapProperty;
}

const extras = system({
  appearance: true,
  backfaceVisibility: true,
  cursor: true,
  objectFit: true,
  outline: true,
  resize: true,
  textDecoration: true,
  textTransform: true,
  transform: true,
  transition: true,
  userSelect: true,
  whiteSpace: true,
  wordBreak: true,
  wordWrap: true,
});

interface FontWeightProps {
  fontWeight?: ResponsiveValue<FontWeightProperty | string>;
}

export type StyledSystemProps = BackgroundProps &
  BorderProps &
  ButtonStyleProps &
  ColorProps &
  ExtraProps &
  FlexboxProps &
  FontWeightProps &
  GridProps &
  LayoutProps &
  Omit<PositionProps, 'zIndex'> &
  ZIndexProps &
  WebkitOverflowScrollingProps &
  ShadowProps &
  SpaceProps &
  TextStyleProps &
  Omit<TypographyProps, 'fontWeight'>;

export const styledSystem = compose(
  background,
  border,
  buttonStyle,
  color,
  extras,
  flexbox,
  grid,
  layout,
  opacity,
  position,
  shadow,
  space,
  textStyle,
  typography,
  zIndex,
);

export const shouldForwardProp = createShouldForwardProp(
  styledSystem.propNames!,
);
