import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetAnonymousContactInfoByIdOpenQuery,
  GetAnonymousContactInfoByIdOpenQueryVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import GET_ANONYMOUS_CONTACT_INFO_BY_ID_OPEN_QUERY from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/open/getAnonymousContactInfoByIdOpen.graphql';

export const useGetAnonymousContactInfoByIdOpen = (
  options: QueryHookOptions<
    GetAnonymousContactInfoByIdOpenQuery,
    GetAnonymousContactInfoByIdOpenQueryVariables
  >,
) =>
  useQuery<
    GetAnonymousContactInfoByIdOpenQuery,
    GetAnonymousContactInfoByIdOpenQueryVariables
  >(GET_ANONYMOUS_CONTACT_INFO_BY_ID_OPEN_QUERY, options);
