import { Flex, FlexProps, navbarHeight } from '@amzn/ring-ui-react-components';
import { ReactNode } from 'react';
import { matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import { useHasWebViewUserAgent } from 'src/hooks';
import { AppRoutes } from 'src/routes';
import { NeighborsWebNavbar } from 'src/components/Navbar';
import { Announcement } from 'src/components/Announcement';
import { useGetAnnouncement } from 'src/components/Announcement/hooks/useGetAnnouncement';

interface AppLayoutProps {
  children: ReactNode;
}

const ROUTES_WITHOUT_APPBAR = [
  AppRoutes.EventCreate,
  AppRoutes.EmergencyInfoTopics,
  AppRoutes.EmergencyInfoTopic,
  AppRoutes.BestOfRingVideo,
];

export const AppLayout = ({ children }: AppLayoutProps) => {
  const { pathname } = useLocation();
  const isRouteWithoutAppbar = ROUTES_WITHOUT_APPBAR.some((route) =>
    matchPath(pathname, { path: route, exact: true }),
  );
  const isWebViewUserAgent = useHasWebViewUserAgent();
  const dealsOnWebView = useRouteMatch(AppRoutes.Deals) && isWebViewUserAgent;

  const announcement = useGetAnnouncement();

  if (isWebViewUserAgent) {
    return <Layout>{children}</Layout>;
  }

  const hideNavbar = isRouteWithoutAppbar || dealsOnWebView;
  return (
    <>
      {announcement && (
        <Announcement
          message={announcement.message}
          isUrgent={announcement.is_urgent}
          link={announcement.link}
        />
      )}
      {!hideNavbar && <NeighborsWebNavbar />}
      <Layout pt={hideNavbar ? 0 : navbarHeight}>{children}</Layout>
    </>
  );
};

const Layout = ({ children, ...props }: FlexProps) => (
  <Flex
    {...props}
    as="main"
    width="100%"
    bg="neutral.50"
    flex={1}
    data-testid="app-layout"
  >
    <Flex direction="column" flex={1} w="100%">
      {children}
    </Flex>
  </Flex>
);
