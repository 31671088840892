import { forwardRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { isLocalBuild } from 'src/utils';
import { Box, BoxProps } from '../Box';
import { useModalContext } from './ModalContext';
import { px, py } from './utils';

type ModalBodyProps = Omit<BoxProps, 'id' | 'overflow'>;

export const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(
  (props, ref) => {
    const { bodyId, setBodyMounted, scrollBehavior } = useModalContext();

    const overflow = {
      _: 'auto',
      m: scrollBehavior === 'inside' ? 'auto' : 'unset',
    };

    useIsomorphicLayoutEffect(() => {
      setBodyMounted(true);
      return () => setBodyMounted(false);
    }, []);

    return (
      <Box
        flex={1}
        py={py}
        px={px}
        {...props}
        ref={ref}
        id={bodyId}
        overflow={overflow}
      />
    );
  },
);

if (isLocalBuild) {
  ModalBody.displayName = 'ModalBody';
}
