import { defaultBorderTop } from '@amzn/ring-ui-react-components';
import { forwardRef } from 'react';
import { isLocalBuild } from 'src/utils';
import { BoxProps } from '../Box';
import { Flex } from '../Flex';
import { px, py } from './utils';

type ModalFooterProps = Omit<BoxProps, 'footer'>;

export const ModalFooter = forwardRef<HTMLElement, ModalFooterProps>(
  (props, ref) => (
    <Flex
      alignItems="center"
      py={py}
      px={px}
      justifyContent="flex-end"
      flex="0 0 auto"
      flexDirection={{ _: 'column', m: 'row' }}
      {...defaultBorderTop}
      {...props}
      as="footer"
      ref={ref}
    />
  ),
);

if (isLocalBuild) {
  ModalFooter.displayName = 'ModalFooter';
}
