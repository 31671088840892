import { useConst } from '@amzn/ring-ui-react-components';
import { ApolloError, isApolloError } from '@apollo/client';
import { ComponentProps } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary';
import { NeighborsErrorCodes } from 'src/apollo';
import { hasGqlErrorCode } from 'src/apollo/utils';
import { getEventSecurityLogContext, logError } from 'src/logs';
import { CenteredLoading } from 'src/shared/components/Loading';
import { Error404, Error500, ErrorDefault } from '../Error';

type ReactErrorBoundaryProps = ComponentProps<typeof ReactErrorBoundary>;

type ErrorBoundaryProps = Pick<ReactErrorBoundaryProps, 'children'>;

export const ErrorBoundary = (props: ErrorBoundaryProps) => (
  <ReactErrorBoundary
    {...props}
    FallbackComponent={FallbackComponent}
    onError={handleBoundaryError}
  />
);

interface ErrorFallBackProps extends Omit<FallbackProps, 'error'> {
  error: Error | ApolloError;
}

const FallbackComponent = ({
  error,
  resetErrorBoundary,
}: ErrorFallBackProps) => {
  const code = useConst(() => {
    if (isApolloError(error)) {
      if (hasGqlErrorCode(error, NeighborsErrorCodes.SessionTimeout)) {
        return NeighborsErrorCodes.SessionTimeout;
      } else if (hasGqlErrorCode(error, NeighborsErrorCodes.NotFound)) {
        return NeighborsErrorCodes.NotFound;
      } else if (hasGqlErrorCode(error, NeighborsErrorCodes.ServerError)) {
        return NeighborsErrorCodes.ServerError;
      }
    }

    return null;
  }) as unknown as NeighborsErrorCodes | null;

  switch (code) {
    // User session timed out and the redirectLink is redirecting them to login
    case NeighborsErrorCodes.SessionTimeout:
      return <CenteredLoading />;
    // Specific to the Detail/Share page as for now, the event was not found (invalid event id was provided)
    case NeighborsErrorCodes.NotFound:
      return <Error404 onClick={resetErrorBoundary} />;
    case NeighborsErrorCodes.ServerError:
      return <Error500 onClick={resetErrorBoundary} />;
    default:
      return <ErrorDefault onClick={resetErrorBoundary} />;
  }
};

const EXPECTED_BOUNDARY_ERROR_MSGS = [
  'could not find resource',
  'could not complete the request',
];

const handleBoundaryError: ReactErrorBoundaryProps['onError'] = (
  error,
  { componentStack },
) => {
  let message = '';
  if (EXPECTED_BOUNDARY_ERROR_MSGS.includes(error.message)) {
    message = `[Expected Boundary error]: ${error.message}`;
  } else {
    message = `[Unexpected Boundary error]: ${error.message}`;
  }

  const successStatus = 'success: false';

  const context = {
    error,
    componentStack,
    successStatus,
    url: window.location.href,
  };

  logError(message, getEventSecurityLogContext(context));
};
