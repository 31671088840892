import styled from 'styled-components';

import { Box } from 'src/shared/components/Box';
import { Radii } from 'src/shared/theme';

export const ImageGalleryWrapper = styled(Box)`
  border-radius: 0px;
  position: relative;

  .image-gallery.fullscreen-modal {
    z-index: ${({ theme }) => theme.zIndices['videoFullscreen']};

    .event-gallery-fullscreen-button {
      transform: translateY(100%);
    }
  }

  .image-gallery-content:not(.fullscreen) {
    .image-gallery-slide-wrapper {
      overflow: hidden;
    }

    /* it fixes the border radius glitch when you swipe */
    .image-gallery-slides {
      border-radius: ${({ theme, borderRadius }) =>
        borderRadius ? `${theme.radii[borderRadius as Radii]}px` : 0};
      overflow: hidden;
    }
  }

  .show-thumbnails
    .image-gallery-content:not(.fullscreen)
    .image-gallery-slide-wrapper {
    margin-bottom: ${({ theme }) => theme.space[3]}px;
  }

  .image-gallery-thumbnails-container {
    text-align: left;
    display: flex;
    justify-content: center;
    ${({ theme }) => theme.mediaQueries.m} {
      flex-direction: row;
      flex: 1;
      justify-content: flex-start;
    }
  }

  .image-gallery-thumbnails {
    padding: 0;
  }

  .image-gallery-bullets {
    .image-gallery-bullet {
      appearance: none;
      background-color: rgba(255, 255, 255, 0.65);
      border: 0;
      box-shadow: none;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      margin: 0 4px;
      outline: none;
      padding: 4px;
      transition: all 0.2s ease-out;
    }
    .image-gallery-bullet.active {
      transform: scale(1);
      border: 0;
      background: #1998d5;
    }
  }

  .show-dashes .image-gallery-thumbnails {
    padding: 5px 12px;
  }

  .image-gallery-thumbnail {
    position: relative;
    border: none;
    margin-right: ${({ theme }) => theme.space[2]}px;
    flex-basis: 20%;

    ${({ theme }) => theme.mediaQueries.m} {
      display: inline-block;
      width: 120px;
    }

    &:last-child {
      margin-right: 0;
      margin-left: 0;
    }

    &.active,
    &:hover,
    &:focus {
      border: none;
      transition: none;
    }
  }
`;
