import { ChangeEvent, MouseEventHandler, useState } from 'react';
import { callAllHandlers } from '@chakra-ui/utils';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Textarea,
  useTheme,
} from '@amzn/ring-ui-react-components';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalOverlay,
} from '../Modal';
import { useIsDesktop } from 'src/shared/hooks';
import { useEventCardUpdate } from './context';

export interface EventCardResolvedModalProps
  extends Omit<ModalProps, 'children'> {
  onSubmit: () => void;
}

const maxLength = 2500;

export const EventCardResolvedModal = ({
  isOpen,
  onClose,
  onSubmit,
}: EventCardResolvedModalProps) => {
  const theme = useTheme();
  const { setResolvedMessage } = useEventCardUpdate();
  const [length, setLength] = useState(0);
  const isDesktop = useIsDesktop();
  const cancelButtonVariant = isDesktop ? 'outline' : 'ghost';
  const handleChange = callAllHandlers(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setResolvedMessage(e.target.value.trim());
    },
    (e) => {
      setLength(e.target.value.length);
    },
  );
  const handleClose = callAllHandlers<
    MouseEventHandler<HTMLDivElement | HTMLButtonElement>
  >(() => setLength(0), onClose);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xlg" isCentered>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader justifyContent="center" py={4}>
            <Box textStyle="body-normal-medium">Incident Status</Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={{ base: 6, m: 8 }} py={6}>
            <Box textStyle="heading-normal-medium" mb={2}>
              That’s great news!
            </Box>
            <Box mb={2} textStyle="body-large-light">
              Please share an update with your neighbors about why this incident
              is resolved.
            </Box>
            <FormControl>
              <FormHelperText
                textAlign="right"
                textStyle="body-small-light"
                mb={1}
              >{`${length}/${maxLength}`}</FormHelperText>
              <Textarea
                id="textarea"
                data-testid="resolve-modal-textarea"
                maxLength={maxLength}
                onChange={handleChange}
                placeholder="Add your update"
                borderColor="tertiary.700"
                rows={6}
                _hover={{
                  borderColor: 'tertitary.700',
                }}
                _focus={{
                  borderColor: 'primary.500',
                  boxShadow: `0 0 0 1px ${theme.colors.primary[500]}`,
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter
            px={6}
            py={4}
            flexDirection={{
              base: 'column-reverse',
              m: 'row',
            }}
            borderTop={{ m: 'none' }}
          >
            <Box
              as={Button}
              variant={cancelButtonVariant}
              onClick={handleClose}
              mr={{ base: 0, m: 2 }}
              width={{ base: '100%', m: 200 }}
            >
              Cancel
            </Box>
            <Box
              as={Button}
              onClick={onSubmit}
              mb={{ base: 3, m: 0 }}
              width={{ base: '100%', m: 200 }}
            >
              Resolve
            </Box>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
