import { Flex, FlexProps } from '@amzn/ring-ui-react-components';
import { useMemo } from 'react';
import {
  getDistanceInMiles,
  lowerThanMaxDistanceInMiles,
} from 'src/shared/utils';
import { Distance } from '../Distance';
import { DateHelperText } from '../HelperText';
import { Location } from '../Location';
import { Plural } from '../Plural';
import { EventCardStateContextValue, useEventCardState } from './context';

export interface EventCardEventInfoProps extends FlexProps {
  isOpenEvent?: boolean;
  isPreviewEvent?: boolean;
}

export const EventCardEventInfo = ({
  isOpenEvent,
  isPreviewEvent,
  ...props
}: EventCardEventInfoProps) => {
  const {
    event: {
      coordinates,
      created_at,
      is_regional,
      time_since_created,
      view_count,
      media_type,
      location,
      hide_elapsed_time,
    },
    eventAreaCenter,
  } = useEventCardState();

  const { showDistance, showViews } = useEventCardInfo({
    eventAreaCenter,
    coordinates,
    isOpenEvent,
    isPreviewEvent,
    is_regional,
    media_type,
    view_count,
  });

  const { address } = location!;

  return (
    <Flex
      data-testid="event-card-event-info"
      textStyle="body-normal-light"
      mb={4}
      flexWrap="wrap"
      {...props}
    >
      {!hide_elapsed_time && (
        <DateHelperText
          mr={mx}
          color="neutral.500"
          createdAt={created_at}
          timeSinceCreated={time_since_created}
          data-testid="elapsed-time"
        />
      )}
      {showDistance ? (
        <Distance mr={mx} from={eventAreaCenter} to={coordinates} />
      ) : (
        <Location
          locality={address?.locality}
          administrativeArea={address?.administrative_area}
          mr={mx}
        />
      )}
      {showViews && <Plural count={view_count}>view</Plural>}
    </Flex>
  );
};

// TODO: add stack components, HStack, VStack, etc
const mx = 3;

interface UseEventCardInfoProps
  extends Pick<EventCardStateContextValue, 'eventAreaCenter'>,
    Pick<
      EventCardStateContextValue['event'],
      'is_regional' | 'media_type' | 'view_count' | 'coordinates'
    > {
  isOpenEvent?: boolean;
  isPreviewEvent?: boolean;
}

const useEventCardInfo = ({
  isPreviewEvent,
  isOpenEvent,
  is_regional,
  eventAreaCenter,
  coordinates,
  view_count,
  media_type,
}: UseEventCardInfoProps) => {
  const isLowerThanMaxDistance = useMemo(() => {
    const distance = getDistanceInMiles({
      from: eventAreaCenter,
      to: coordinates,
    });

    const result = lowerThanMaxDistanceInMiles(distance);

    return result;
  }, [coordinates, eventAreaCenter]);

  const showDistance =
    isPreviewEvent || (!isOpenEvent && !is_regional && isLowerThanMaxDistance);

  const showViews = view_count > 0 && media_type === 'video';

  return {
    showDistance,
    showViews,
  };
};
