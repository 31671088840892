import {
  PersonDescription,
  PersonDescriptionAdditionalDetails,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { Box } from '@amzn/ring-ui-react-components';
import { EventProperty } from './EventProperty';
import { useTranslation } from 'react-i18next';

export interface EventCardPersonInfoProps {
  personDescription: PersonDescription;
  index: number;
}

export const EventCardPersonInfo = ({
  index,
  personDescription: { trait, attire, additional_details },
}: EventCardPersonInfoProps) => {
  const { t } = useTranslation('components.event');
  return (
    <Box mt={4}>
      {(trait || attire || additional_details) && (
        <Box
          textStyle="body-normal-medium"
          data-testid="person-descripition-header"
        >
          {t('person-info.title', { spacing: index ? ` ${index} ` : ' ' })}
        </Box>
      )}
      <EventProperty
        label={t('person-info.clothing')}
        value={attire}
        show={!!attire}
      />
      <EventProperty
        label={t('person-info.unique-traits')}
        value={trait}
        show={!!trait}
      />
      {additional_details &&
        (({ age, gender, race }: PersonDescriptionAdditionalDetails) => (
          <div data-testid="person-additional-details">
            <EventProperty
              label={t('person-info.age')}
              value={`${age}`}
              show={!!age}
            />
            <EventProperty
              label={t('person-info.sex')}
              value={gender}
              show={!!gender}
            />
            <EventProperty
              label={t('person-info.race')}
              value={race}
              show={!!race}
            />
          </div>
        ))(additional_details)}
    </Box>
  );
};
