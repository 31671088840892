import { defaultBorderBottom } from '@amzn/ring-ui-react-components';
import { forwardRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { BoxProps } from '../Box';
import { Flex } from '../Flex';
import { useModalContext } from './ModalContext';
import { px, py } from './utils';

type ModalHeaderProps = Omit<BoxProps, 'as' | 'id'>;

export const ModalHeader = forwardRef<HTMLElement, ModalHeaderProps>(
  (props, ref) => {
    const { headerId, setHeaderMounted } = useModalContext();

    useIsomorphicLayoutEffect(() => {
      setHeaderMounted(true);
      return () => setHeaderMounted(false);
    }, []);

    return (
      <Flex
        data-testid="modal-header"
        flex="0 0 auto"
        textStyle="body-normal-regular"
        py={py}
        px={px}
        {...defaultBorderBottom}
        {...props}
        ref={ref}
        as="header"
        id={headerId}
      />
    );
  },
);

ModalHeader.displayName = 'ModalHeader';
