import { border } from 'polished';
import { MutableRefObject, forwardRef, useEffect } from 'react';
import { useEnsuredForwardedRef } from 'react-use';

import { Box, BoxProps } from '../Box';
import { ControlBox } from '../ControlBox';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { VisuallyHidden } from '../VisuallyHidden';

type InputProps = BoxProps<'input'>;

export interface CheckboxProps extends InputProps {
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  indeterminate?: boolean;
  invalid?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      checked,
      children,
      defaultChecked,
      disabled,
      id,
      indeterminate = false,
      invalid,
      fullWidth,
      name,
      onBlur,
      onChange,
      onFocus,
      readOnly,
      size = 'md',
      value,
      ...props
    },
    ref,
  ) => {
    const ensuredRef = useEnsuredForwardedRef(
      ref as MutableRefObject<HTMLInputElement>,
    );

    useEffect(() => {
      if (!ensuredRef.current) {
        return;
      }

      ensuredRef.current.indeterminate = indeterminate;
    }, [indeterminate, ensuredRef]);

    return (
      <Flex
        as="label"
        display="inline-flex"
        verticalAlign="top"
        alignItems="center"
        width={fullWidth ? '100%' : undefined}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        {...props}
      >
        <VisuallyHidden
          aria-checked={indeterminate ? 'mixed' : checked}
          aria-invalid={invalid}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-readonly={readOnly}
          as="input"
          checked={readOnly ? checked : defaultChecked ? undefined : checked}
          defaultChecked={readOnly ? undefined : defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={readOnly ? undefined : onChange}
          onFocus={onFocus}
          readOnly={readOnly}
          ref={ensuredRef}
          type="checkbox"
          value={value}
        />
        <ControlBox
          {...border('2px', 'solid', 'tertiary.700')}
          color="white"
          opacity={readOnly ? 0.8 : 1}
          _checked={{
            bg: 'primary.500',
            borderColor: 'primary.500',
          }}
          _checkedDisabled={{
            borderColor: '',
            bg: '',
            color: '',
          }}
          _disabled={{
            bg: '',
          }}
          _invalid={{
            borderColor: 'negative.500',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          userSelect="none"
          transition="background-color 120ms, box-shadow 250ms"
          size={sizes[size]}
          borderRadius="round"
        >
          <Icon
            name={indeterminate ? 'minus' : 'check'}
            size={10}
            transition="transform 240ms, opacity 240ms"
          />
        </ControlBox>
        {children && (
          <Box
            ml={2}
            fontSize={size}
            opacity={disabled ? 0.4 : 1}
            width="100%"
            userSelect="none"
          >
            {children}
          </Box>
        )}
      </Flex>
    );
  },
);

const sizes = {
  lg: '1.5rem',
  md: '1rem',
  sm: 'auto',
};
