import { forwardRef } from 'react';
import { isLocalBuild } from 'src/utils';
import { Box, BoxBasedComponentProps } from '../Box';
import { icons } from './Icons';

export type IconItem = {
  path: JSX.Element;
  viewBox?: string;
};

export type IconName = keyof typeof icons;

export type IconProps = BoxBasedComponentProps<
  'svg',
  {
    name: IconName;
    role?: 'presentation' | 'img';
  },
  | 'as'
  | 'backfaceVisibility'
  | 'display'
  | 'flexShrink'
  | 'sx'
  | 'verticalAlign'
>;

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      children,
      color = 'currentColor',
      focusable = false,
      name,
      role = 'presentation',
      size = '1em',
      ...props
    },
    ref,
  ) => {
    const { path, viewBox = '0 0 24 24' } = icons[name] as IconItem;

    return (
      <Box
        {...props}
        ref={ref}
        as="svg"
        backfaceVisibility="hidden"
        color={color}
        display="inline-block"
        flexShrink={0}
        focusable={focusable}
        role={role}
        size={size}
        verticalAlign="middle"
        viewBox={viewBox}
        sx={{
          '&:not(:root)': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
        {path}
      </Box>
    );
  },
);

if (isLocalBuild) {
  Icon.displayName = 'Icon';
}
