import { Box, Flex } from '@amzn/ring-ui-react-components';
import { usePushToFeed } from 'src/hooks';
import { usePushToHistory } from 'src/hooks/usePushToHistory';
import { AppRoutes } from 'src/routes';
import { CenteredLoading } from 'src/shared/components/Loading';
import { NoPosts } from './NoPosts';
import { PostsInReview } from './PostsInReview';
import { useGetUserEventsHistory } from './useGetUserEventsHistory';
import { useEffect, useRef, useState } from 'react';
import {
  useNeighborsWebClickEventStreamDispatcher,
  useNeighborsWebViewEventStreamDispatcher,
} from 'src/components/App/hooks';
import { useAuth, useFeature } from 'src/context';
import { useTranslation } from 'react-i18next';
import { PostStatus } from 'src/types';
import {
  addStatusToEvents,
  convertNpssScheduledPostToEventAndAddStatus,
  ScheduledEvent,
  sortEventsDescendingByDateCreated,
  sortScheduledEventsAscendingByScheduledFor,
} from 'src/utils/events';
import { useGetDeniedPostsPages } from './hooks/useGetDeniedPostsPages';
import { DeniedPostPage } from '@amzn/ring-neighbors-contentful-gql-schema';

import {
  CalendarTimelineDefaultIcon,
  CheckIcon,
  DisableCircleIcon,
  Page,
  PageBody,
  QuestionmarkCircleIcon,
  SidebarNavigation,
  SidebarNavigationCell,
  SidebarNavigationHeader,
  SidebarNavigationSection,
} from '@amzn/ring-safe-web';
import './navbar.css';
import { SubNavbar } from 'src/shared/components/SubNavbar';
import { BackButton } from 'src/shared/components/BackButton';
import { useIsMobile } from 'src/shared/hooks';
import { Event } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { useIsNpssUser } from 'src/auth';
import { useGetNpssScheduledPosts } from 'src/features/MyPosts/hooks/useGetNpssScheduledPosts';
import { useEventSchedulePostUpdate } from 'src/shared/components/EventCard/hooks/useEventSchedulePostUpdate';
import { useGetNpssPublishedPosts } from 'src/features/MyPosts/hooks/useGetNpssPublishedPosts';
import { toast } from 'src/shared/components/Toast';

type UnifiedMyPostCardProps = {
  events: Event[];
  handleNoPostsClick: () => void;
  handleResolvePostClick: () => void;
  dataTestId: string;
  handleReSchedulePostClick?: (id: string, scheduledFor: string) => void;
  handleEditScheduledPostClick?: (
    id: string,
    title: string,
    description: string,
  ) => void;
  denialReasons?: DeniedPostPage[];
};
export const UnifiedMyPostCard = ({
  events,
  handleNoPostsClick,
  handleResolvePostClick,
  handleReSchedulePostClick,
  handleEditScheduledPostClick,
  dataTestId,
  denialReasons,
}: UnifiedMyPostCardProps): JSX.Element =>
  events.length === 0 ? (
    <NoPosts onClick={handleNoPostsClick} />
  ) : (
    <PostsInReview
      events={events}
      mb={6}
      handleResolvePostClick={handleResolvePostClick}
      handleReSchedulePostClick={handleReSchedulePostClick}
      handleEditScheduledPostClick={handleEditScheduledPostClick}
      data-testid={dataTestId}
      denialReasons={
        denialReasons && denialReasons.length > 0
          ? (denialReasons as DeniedPostPage[])
          : undefined
      }
    />
  );

export const UnifiedMyPostsPage = () => {
  const { t } = useTranslation('features.myPosts');
  const pushToFeed = usePushToFeed();
  const pushToEventCreate = usePushToHistory(AppRoutes.EventCreate);
  const { data, loading } = useGetUserEventsHistory();
  const { data: denialReasons } = useGetDeniedPostsPages();
  const { data: npssScheduledPosts, loading: loadingNpssScheduledPosts } =
    useGetNpssScheduledPosts();
  const { data: npssPublishedPosts, loading: loadingNpssPublishedPosts } =
    useGetNpssPublishedPosts();
  const pageViewRef = useRef(false);
  const dispatchView =
    useNeighborsWebViewEventStreamDispatcher('nhWeb_myPosts');
  const { isPending } = useAuth();
  const enablePostCallout = useFeature('ENABLE_POST_CALLOUT');
  const isMobile = useIsMobile() || false;
  const [activeItem, setActiveItem] = useState(0);
  const [isNavbarHidden, setIsNavbarHidden] = useState(false);
  const isNpssUser = useIsNpssUser();

  const handleOnCompleted = () => {
    toast.success(t('update-schedule-post-toast.success'));
  };

  const handleOnError = () => {
    toast.error(t('update-schedule-post-toast.error'));
  };

  const updateScheduledPost = useEventSchedulePostUpdate(
    handleOnCompleted,
    handleOnError,
  );

  const handleReScheduledPostClick = (id: string, scheduledFor: string) => {
    updateScheduledPost({ id, scheduled_for: scheduledFor });
  };

  const handleEditScheduledPostClick = (
    id: string,
    title: string,
    description: string,
  ) => {
    updateScheduledPost({ id, title, description });
  };

  const handleClick = (n: number) => {
    setActiveItem(n);
    if (isMobile) {
      setIsNavbarHidden(true);
    }
  };

  useEffect(() => {
    if (pageViewRef.current || isPending) return;

    dispatchView();
    pageViewRef.current = true;
  }, [dispatchView, isPending]);

  const dispatchClick =
    useNeighborsWebClickEventStreamDispatcher('nhWeb_myPosts');

  const handleResolvePostClick = () => {
    if (dispatchClick) {
      dispatchClick('nhWeb_resolvePost', ['Neighbors Web']);
    }
  };

  const handleNoPostsClick = () => {
    if (dispatchClick) {
      dispatchClick('nhWeb_createPost', undefined, {
        itemType: 'button',
      });
    }
    pushToEventCreate();
  };

  const { approved = [], pending = [], rejected = [] } = data;

  const pendingEvents = addStatusToEvents(pending, PostStatus.PENDING);
  const approvedEvents = addStatusToEvents(approved, PostStatus.APPROVED);
  const rejectedEvents = enablePostCallout
    ? addStatusToEvents(rejected, PostStatus.DENIAL)
    : [];

  const scheduledEvents = sortScheduledEventsAscendingByScheduledFor(
    convertNpssScheduledPostToEventAndAddStatus(
      npssScheduledPosts,
      PostStatus.SCHEDULED,
    ) as ScheduledEvent[],
  );

  const publishedEvents = sortEventsDescendingByDateCreated(
    addStatusToEvents(
      npssPublishedPosts as unknown[] as Event[],
      PostStatus.PUBLISHED,
    ),
  );

  const sidebarNavigationCells = [
    {
      key: 'active-posts',
      title: t('active-posts'),
      icon: <CheckIcon size={32} />,
      dataTestId: 'sidenav-active-posts',
      isNpssItem: false,
    },
    {
      key: 'pending-posts',
      title: t('posts-in-review'),
      icon: <QuestionmarkCircleIcon size={32} />,
      dataTestId: 'sidenav-pending-posts',
      isNpssItem: false,
    },
    {
      key: 'denied-posts',
      title: t('denied-posts'),
      icon: <DisableCircleIcon size={32} />,
      dataTestId: 'sidenav-denied-posts',
      isNpssItem: false,
    },
    {
      key: 'published-posts',
      title: t('published-posts'),
      icon: <CheckIcon size={32} />,
      dataTestId: 'sidenav-npss-published-posts',
      isNpssItem: true,
    },
    {
      key: 'scheduled-posts',
      title: t('scheduled-posts'),
      icon: <CalendarTimelineDefaultIcon size={32} />,
      dataTestId: 'sidenav-npss-scheduled-posts',
      isNpssItem: true,
    },
  ];

  return (
    <>
      {loading ||
      loadingNpssScheduledPosts ||
      loadingNpssPublishedPosts ? null : (
        <SubNavbar>
          <BackButton
            onClick={
              isMobile && isNavbarHidden
                ? () => setIsNavbarHidden(false)
                : pushToFeed
            }
            w="auto"
          >
            {t('back')!}
          </BackButton>
        </SubNavbar>
      )}
      <Page>
        <SidebarNavigation className={isNavbarHidden ? 'hide-navbar' : ''}>
          <SidebarNavigationHeader icon={<></>} title="My Posts" />
          <SidebarNavigationSection title="">
            {sidebarNavigationCells
              .filter((cell) => cell.isNpssItem === isNpssUser)
              .map((cell, index) => (
                <SidebarNavigationCell
                  key={cell.key}
                  checked={activeItem === index}
                  onClick={() => handleClick(index)}
                  image={cell.icon}
                  title={cell.title}
                  data-testid={cell.dataTestId}
                >
                  {/* Empty */}
                </SidebarNavigationCell>
              ))}
          </SidebarNavigationSection>
        </SidebarNavigation>
        <PageBody>
          <Flex
            direction="column"
            flex={1}
            maxWidth={{ m: 603 }}
            mx="auto"
            px={{ base: 6, m: 0 }}
            py={7}
          >
            {loading ||
            loadingNpssScheduledPosts ||
            loadingNpssPublishedPosts ? (
              <CenteredLoading />
            ) : (
              <Box position="relative">
                {activeItem === 0 &&
                  (!isNpssUser ? (
                    <UnifiedMyPostCard
                      events={approvedEvents}
                      handleNoPostsClick={handleNoPostsClick}
                      handleResolvePostClick={handleResolvePostClick}
                      dataTestId="active-posts"
                    />
                  ) : (
                    <UnifiedMyPostCard
                      events={publishedEvents}
                      handleNoPostsClick={handleNoPostsClick}
                      handleResolvePostClick={handleResolvePostClick}
                      dataTestId="published-posts"
                    />
                  ))}
                {activeItem === 1 &&
                  (!isNpssUser ? (
                    <UnifiedMyPostCard
                      events={pendingEvents}
                      handleNoPostsClick={handleNoPostsClick}
                      handleResolvePostClick={handleResolvePostClick}
                      dataTestId="pending-posts"
                    />
                  ) : (
                    <UnifiedMyPostCard
                      events={scheduledEvents}
                      handleNoPostsClick={handleNoPostsClick}
                      handleResolvePostClick={handleResolvePostClick}
                      handleReSchedulePostClick={handleReScheduledPostClick}
                      handleEditScheduledPostClick={
                        handleEditScheduledPostClick
                      }
                      dataTestId="scheduled-posts"
                    />
                  ))}
                {activeItem === 2 && !isNpssUser && (
                  <UnifiedMyPostCard
                    events={rejectedEvents}
                    handleNoPostsClick={handleNoPostsClick}
                    handleResolvePostClick={handleResolvePostClick}
                    dataTestId="rejected-posts"
                    denialReasons={denialReasons as DeniedPostPage[]}
                  />
                )}
              </Box>
            )}
          </Flex>
        </PageBody>
      </Page>
    </>
  );
};
