import { AuthStatus } from 'src/auth';
import { useAuth } from 'src/context';
import { AuthenticatedNavbar } from './AuthenticatedNavbar';
import { NavbarBase } from './NavbarBase';
import { UnauthenticatedNavbar } from './UnauthenticatedNavbar';
import { getViewFromRoutePath } from 'src/components/App/hooks/useNeighborsWebViewEventStreamDispatcher';
import { useLocation } from 'react-router-dom';
import { useNeighborsWebClickEventStreamDispatcher } from '../App/hooks';
import { NpssNavBar } from 'src/components/Navbar/NpssNavBar';

export const NeighborsWebNavbar = () => {
  const { authStatus, isNpssUser } = useAuth();
  const location = useLocation();
  const viewType = getViewFromRoutePath(location.pathname);
  const dispatchClick = useNeighborsWebClickEventStreamDispatcher(viewType);

  switch (authStatus) {
    case AuthStatus.pending:
      return <NavbarBase />;
    case AuthStatus.authenticated:
      if (isNpssUser) {
        return <NpssNavBar />;
      }
      return <AuthenticatedNavbar dispatchEventStreamClick={dispatchClick} />;
    case AuthStatus.unauthenticated:
      return <UnauthenticatedNavbar />;
  }
};
