import type { Pin } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { createContext } from '@chakra-ui/react-utils';
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import type { NonNullableAlertArea } from 'src/types';
import { getDefaultAlertAreaId, getLocationCoordsAndBounds } from 'src/utils';
import { useAlertAreaIdByRingLocation } from './hooks/useAlertAreaIdByRingLocation';
import { getIsNpssUser } from 'src/auth';
import { NpssAccount } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

export interface AlertAreaStateContextValue {
  alertAreas: NonNullableAlertArea[];
  selectedAlertArea: NonNullableAlertArea | undefined;
  selectedAlertAreaBounds: Pin[] | undefined;
  selectedAlertAreaCenter: [number, number];
}

const [AlertAreaStateContextProvider, useAlertAreaState] =
  createContext<AlertAreaStateContextValue>({
    name: 'AlertAreaStateContext',
  });

const [AlertAreaDispatchContextProvider, useAlertAreaDispatch] = createContext<
  Dispatch<SetStateAction<string | undefined>>
>({
  name: 'AlertAreaDispatchContext',
});

interface AlertAreaProviderProps {
  alertAreas: NonNullableAlertArea[];
  children: ReactNode;
  npssUser: NpssAccount | null;
}

const selectedAlertAreaLocalStorageKey = 'selected-alert-area';

export const AlertAreaProvider = ({
  alertAreas,
  children,
  npssUser,
}: AlertAreaProviderProps) => {
  const alertAreaIdByRingLocation = useAlertAreaIdByRingLocation(alertAreas);

  const [selectedAlertAreaId, setSelectedAlertAreaId] = useLocalStorage(
    selectedAlertAreaLocalStorageKey,
    alertAreaIdByRingLocation,
    {
      raw: true,
    },
  );

  const selectedAlertArea = useMemo(() => {
    const selectedAlertArea = alertAreas.find(
      ({ id }) => id === selectedAlertAreaId,
    );

    return selectedAlertArea;
  }, [alertAreas, selectedAlertAreaId]);

  const stateValue = useMemo(() => {
    let coords: [number, number];
    let bounds: Pin[][][] | undefined;
    const isNpssUser = getIsNpssUser();

    if (isNpssUser && npssUser) {
      bounds = npssUser.feed_boundary_geojson?.bounds;
      coords = [0, 0];
    } else {
      ({ coords, bounds } = getLocationCoordsAndBounds(
        selectedAlertArea?.location,
      ));
    }

    return {
      alertAreas,
      selectedAlertArea,
      selectedAlertAreaCenter: coords,
      selectedAlertAreaBounds: bounds ? bounds[0]?.[0] : undefined,
    };
  }, [alertAreas, selectedAlertArea, npssUser]);

  useEffect(() => {
    if (alertAreas.length === 0 || selectedAlertArea) {
      return;
    }

    if (alertAreaIdByRingLocation) {
      setSelectedAlertAreaId(alertAreaIdByRingLocation);
      return;
    }

    const defaultAlertAreaId = getDefaultAlertAreaId(alertAreas);

    setSelectedAlertAreaId(defaultAlertAreaId);
  }, [
    alertAreas,
    selectedAlertArea,
    alertAreaIdByRingLocation,
    setSelectedAlertAreaId,
  ]);

  return (
    <AlertAreaStateContextProvider value={stateValue}>
      <AlertAreaDispatchContextProvider value={setSelectedAlertAreaId}>
        {children}
      </AlertAreaDispatchContextProvider>
    </AlertAreaStateContextProvider>
  );
};

export { useAlertAreaState, useAlertAreaDispatch };
