import { useIsMobileOS } from './useIsMobileOS';
import { useCopyWindowLocationToClipboard } from './useCopyToClipboard';
import { useNavigatorShare } from './useNavigatorShare';
import { useIsAndroidOS } from './useIsAndroidOS';
import { useHasWebViewUserAgent } from 'src/hooks';
import { useEffect } from 'react';

type useShareButtonActionProps = {
  shareUrl: string;
  title: string;
  referringItem?: string;
};

const addQueryParameterToShareUrl = (shareUrl: string): string => {
  const shareUrlWithQueryParam = new URL(shareUrl);
  shareUrlWithQueryParam.searchParams.set('source', 'share');
  return shareUrlWithQueryParam.toString();
};

export const useShareButtonAction = ({
  shareUrl,
  title,
  referringItem,
}: useShareButtonActionProps) => {
  const isMobile = useIsMobileOS();
  const isAndroid = useIsAndroidOS();
  const isWebview = useHasWebViewUserAgent();

  const shareUrlWithQueryParam = addQueryParameterToShareUrl(shareUrl);

  const copyWindowLocationToClipboard = useCopyWindowLocationToClipboard(
    shareUrlWithQueryParam,
  );
  const triggerNativeShareApi = useNavigatorShare({
    title,
    shareUrl: shareUrlWithQueryParam,
  });

  const isNativeBridgeEnabled = isMobile && isWebview;

  useEffect(() => {
    if (isNativeBridgeEnabled) {
      const showShareSheetFallback = () => {
        if (isAndroid) {
          copyWindowLocationToClipboard();
        } else {
          triggerNativeShareApi();
        }
      };

      (window as any).ringapp.onMessage = (rawMsg: string) => {
        try {
          const msg = JSON.parse(rawMsg);
          if (msg?.result === 'error') {
            showShareSheetFallback();
          }
        } catch {
          //This is to mitigate bug NHWEB-2526 since the Android app is sending an invalid JSON object in rawMsg
          showShareSheetFallback();
        }
      };
    }
  }, [
    isNativeBridgeEnabled,
    triggerNativeShareApi,
    isAndroid,
    copyWindowLocationToClipboard,
  ]);

  if (isNativeBridgeEnabled) {
    return () => {
      const message = {
        id:
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32) * 100,
          ) + 1,
        operation: 'share',
        body: {
          shareUrl: shareUrlWithQueryParam,
          title,
          referringItem,
        },
      };
      if ((window as any).ringapp?.sendMessage) {
        return (window as any).ringapp?.sendMessage(JSON.stringify(message));
      }
      if (isAndroid) {
        return copyWindowLocationToClipboard();
      } else {
        return triggerNativeShareApi();
      }
    };
  }

  // TODO: Find out how to trigger native share sheet from Android Webview
  // https://jira.atl.ring.com/browse/NA-3117
  if (isAndroid && isWebview) {
    return copyWindowLocationToClipboard;
  }

  if (isMobile === true) {
    return triggerNativeShareApi;
  }

  return copyWindowLocationToClipboard;
};
