import { useEffect } from 'react';
import { useAuth } from 'src/context';
import { useHeap, usePrivacyCookie } from 'src/hooks';
import { useSpectrometer } from './useSpectrometer';
import { useConsentManager } from 'src/components/App/hooks/useConsentManager';

export const useThirdPartyAnalytics = () => {
  const { isPending, isAuthenticated, isNpssUser } = useAuth();

  const isHeapAllowed = usePrivacyCookie('analytics_heap');
  const isTrackingAccepted = useConsentManager();

  const { load, addAuthEventProperties } = useHeap();

  useEffect(() => {
    if (isPending) {
      return;
    }

    // Heap will be enabled only if user has allowed heap tracking before (we can check this through the privacy cookie)
    if (isHeapAllowed) {
      load();
      addAuthEventProperties(isAuthenticated, isNpssUser);
    }
  }, [
    load,
    isPending,
    addAuthEventProperties,
    isAuthenticated,
    isTrackingAccepted,
    isHeapAllowed,
    isNpssUser,
  ]);

  useSpectrometer();
};
