import { useMemo, ReactNode } from 'react';
import { callAllHandlers } from '@chakra-ui/utils';
import { useClickAndKeyDownHandlers } from 'src/shared/hooks';
import { Box, BoxBasedComponentProps } from '../Box';
import { getRel, linkStyles } from '../Link';
import { useDropdownContext } from './DropdownContext';

type ItemType = 'a' | 'div';

export type DropdownItemProps<T extends ItemType = 'div'> =
  BoxBasedComponentProps<
    T,
    {
      disabled?: boolean;
      destructive?: boolean;
      icon?: ReactNode;
      label?: string;
    }
  >;

export const DropdownItem = <T extends ItemType = 'div'>({
  children,
  disabled = false,
  destructive = false,
  icon,
  label,
  onClick,
  ...props
}: DropdownItemProps<T>) => {
  const { onClose, closeOnSelect } = useDropdownContext();

  const color = useMemo(
    () => (destructive ? 'negative' : 'neutral'),
    [destructive],
  );

  const handleOnClick = closeOnSelect
    ? callAllHandlers(onClick, onClose)
    : onClick;

  const handlers = useClickAndKeyDownHandlers(handleOnClick, disabled);

  const hoverStyles = {
    bg: 'tertiary.300',
  };

  const activeStyles = {
    bg: 'tertiary.500',
  };

  return (
    <Box
      data-testid="dropdown-item"
      display="flex"
      justifyContent="start"
      alignItems="center"
      fontSize={2}
      height="auto"
      position="relative"
      px={6}
      py={3}
      textAlign="left"
      outline="none"
      _hoverNotActive={hoverStyles}
      _active={activeStyles}
      {...handlers}
      {...props}
      color={color + '.500'}
      cursor={!disabled ? 'pointer' : undefined}
      tabIndex={0}
    >
      {icon}
      {label}
      {children}
    </Box>
  );
};

type DropdownLinkItemProps = Omit<DropdownItemProps<'a'>, 'as' | 'display'>;

export const DropdownLinkItem = ({
  rel,
  target,
  ...props
}: DropdownLinkItemProps) => {
  const finalRel = getRel(target, rel);

  return (
    <DropdownItem
      {...props}
      {...linkStyles}
      as="a"
      display="block"
      rel={finalRel}
      target={target}
      textDecoration="none"
      textStyle="body-large-medium"
    />
  );
};
