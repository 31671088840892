import { useCallback } from 'react';
import { config } from 'src/config';
import { Heap } from 'src/types/window';

const { HEAP_ID } = config;

export const track: Heap['track'] = (e: string, properties?: Object) => {
  if (!window.heap || !window.heap.loaded) return;
  window.heap.track(e, properties);
};

export const useHeap = () => {
  const load = useCallback(() => {
    if (window.heap && window.heap.loaded) {
      return;
    }

    if (window.heap) {
      window.heap.load(HEAP_ID, {
        rewrite: rewriteFunc,
        disableTextCapture: true,
      });
    }
  }, []);

  const addAuthEventProperties = useCallback(
    (authenticated: boolean, isNpssUser: boolean) => {
      if (window.heap) {
        window.heap.addEventProperties({
          Authenticated: authenticated,
          IsNpssUser: isNpssUser,
        });
      }
    },
    [],
  );

  return {
    load,
    track,
    addAuthEventProperties,
  };
};

interface RewriteProps {
  event_properties: Record<string, any>;
  pageview_properties: Record<string, any>;
  session_properties: Record<string, any>;
}

const rewriteFunc = (props: RewriteProps) => {
  const { event_properties, pageview_properties, session_properties } = props;

  let eventProperties, pageviewProperties, sessionProperties;

  if (event_properties) {
    const { href, hierarchy } = event_properties;

    eventProperties = {
      ...event_properties,
      ...(href && {
        href: applyRegex(href),
      }),
      ...(hierarchy &&
        hierarchy.indexOf('[href') > -1 && {
          hierarchy: applyRegex(hierarchy),
        }),
    };
  }

  if (pageview_properties) {
    const { path, hash, previous_page } = pageview_properties;

    pageviewProperties = {
      ...pageview_properties,
      ...(path && { path: applyRegex(path) }),
      ...(hash && { hash: applyRegex(hash) }),
      ...(previous_page && {
        previous_page: applyRegex(previous_page),
      }),
    };
  }

  if (session_properties) {
    const { path, hash, referrer } = session_properties;

    sessionProperties = {
      ...session_properties,
      ...(path && { path: applyRegex(path) }),
      ...(hash && { hash: applyRegex(hash) }),
      ...(referrer && { referrer: applyRegex(referrer) }),
    };
  }

  return {
    ...props,
    ...(eventProperties && { event_properties: eventProperties }),
    ...(pageviewProperties && { pageview_properties: pageviewProperties }),
    ...(sessionProperties && { session_properties: sessionProperties }),
  };
};

const applyRegex = (str: string | undefined) =>
  str?.replace(/((event\/))([A-Za-z0-9_@.-]+?)(?=\/|\?|$)/g, '$1hidden');
