import { useMediaQuery } from 'react-responsive';

import { useTheme } from '../context';

export const useIsTablet = () => {
  const { breakpoints } = useTheme();

  return useMediaQuery({
    minWidth: breakpoints.s,
    maxWidth: breakpoints.m,
  });
};
