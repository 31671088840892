import type {
  DeleteEventMutation,
  DeleteEventMutationVariables,
  DownvoteEventMutation,
  DownvoteEventMutationVariables,
  HideEventMutation,
  HideEventMutationVariables,
  ReportEventMutation,
  ReportEventMutationVariables,
  UpvoteEventMutation,
  UpvoteEventMutationVariables,
  ResolveEventMutation,
  ResolveEventMutationVariables,
  UnresolveEventMutation,
  UnresolveEventMutationVariables,
  GetEventByIdQuery,
  GetEventByIdQueryVariables,
    UpdateEventMutation,
    UpdateEventMutationVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import DELETE_EVENT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/deleteEvent.graphql';
import DOWNVOTE_EVENT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/downvoteEvent.graphql';
import HIDE_EVENT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/hideEvent.graphql';
import REPORT_EVENT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/reportEvent.graphql';
import UPVOTE_EVENT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/upvoteEvent.graphql';
import RESOLVE_EVENT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/resolveEvent.graphql';
import UNRESOLVE_EVENT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/unresolveEvent.graphql';
import GET_EVENT_BY_ID from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/getEventById.graphql';
import GET_USER_EVENTS_HISTORY from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/me/getUserEventsHistory.graphql';
import UPDATE_EVENT from "@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/updateEvent.graphql";
import { ApolloCache, useMutation } from '@apollo/client';
import { toast } from 'src/shared/components/Toast';
import produce, { Draft } from 'immer';
import { firstComments } from 'src/components/Event/constants';

export interface UseEventMutationProps {
  eventId: string;
  onMutationCompleted?: () => void;
  isResolved?: boolean;
  message?: string;
  title?: string;
  description?: string;
}

export const useEventHideMutation = ({
  eventId,
  onMutationCompleted,
}: UseEventMutationProps) =>
  useMutation<HideEventMutation, HideEventMutationVariables>(HIDE_EVENT, {
    variables: { event_id: eventId },
    onCompleted() {
      toast.success('Post has been hidden');
      onMutationCompleted?.();
    },
    onError() {
      toast.error('Unable to hide post');
    },
  });

export const useEventDeleteMutation = ({
  eventId,
  onMutationCompleted,
}: UseEventMutationProps) =>
  useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DELETE_EVENT, {
    variables: { event_id: eventId },
    refetchQueries: [GET_USER_EVENTS_HISTORY],
    onCompleted() {
      toast.success('Your post has been deleted');
      onMutationCompleted?.();
    },
    onError() {
      toast.error('Unable to delete post');
    },
  });

export const useEventUpvoteMutation = ({
  eventId,
  onMutationCompleted,
}: UseEventMutationProps) =>
  useMutation<UpvoteEventMutation, UpvoteEventMutationVariables>(UPVOTE_EVENT, {
    variables: { event_id: eventId },
    onCompleted() {
      onMutationCompleted?.();
    },
    onError() {
      toast.error('Unable to upvote post');
      onMutationCompleted?.();
    },
  });

export const useEventDownvoteMutation = ({
  eventId,
  onMutationCompleted,
}: UseEventMutationProps) =>
  useMutation<DownvoteEventMutation, DownvoteEventMutationVariables>(
    DOWNVOTE_EVENT,
    {
      variables: { event_id: eventId },
      onCompleted() {
        onMutationCompleted?.();
      },
      onError() {
        toast.error('Unable to downvote post');
      },
    },
  );

export const useEventReportMutation = ({
  eventId,
  onMutationCompleted,
}: UseEventMutationProps) =>
  useMutation<ReportEventMutation, Partial<ReportEventMutationVariables>>(
    REPORT_EVENT,
    {
      variables: {
        event_id: eventId,
      },
      onCompleted() {
        toast.success('Post reported successfully');
        onMutationCompleted?.();
      },
      onError() {
        toast.error('Unable to report post');
      },
    },
  );

export const useEventResolveMutation = ({
  eventId,
  message = '',
}: UseEventMutationProps) =>
  useMutation<ResolveEventMutation, ResolveEventMutationVariables>(
    RESOLVE_EVENT,
    {
      variables: {
        event_id: eventId,
        message,
      },
      update: (cache, { data }) => {
        const { eventResolve } = data!;

        if (!eventResolve?.success) {
          return;
        }

        updateResolveMutations({
          eventId,
          cache,
          recipe: ({ eventById }) => {
            eventById!.resolution_info!.is_resolved = true;
            eventById!.resolution_info!.resolved_message = message;
          },
        });
      },
      onQueryUpdated: (observableQuery) => {
        const { options, queryName } = observableQuery;

        if (
          queryName === 'getEventById' &&
          options?.variables?.event_id === eventId
        ) {
          observableQuery.refetch();
        }
      },
    },
  );

export const useEventUnresolveMutation = ({ eventId }: UseEventMutationProps) =>
  useMutation<UnresolveEventMutation, UnresolveEventMutationVariables>(
    UNRESOLVE_EVENT,
    {
      variables: {
        event_id: eventId,
      },
      update: (cache, { data }) => {
        const { eventUnresolve } = data!;

        if (!eventUnresolve?.success) {
          return;
        }

        updateResolveMutations({
          eventId,
          cache,
          recipe: ({ eventById }) => {
            eventById!.resolution_info!.is_resolved = false;
            eventById!.resolution_info!.resolved_message = '';
          },
        });
      },
    },
  );

export const useEventUpdateMutation = ({ eventId, isResolved, message='', title, description}: UseEventMutationProps) =>
    useMutation<UpdateEventMutation, UpdateEventMutationVariables>(
        UPDATE_EVENT,
        {
            variables: {
                event_id: eventId,
                is_resolved:isResolved,
                resolved_message:message,
                title:title,
                description:description,
            },
            update: (cache, { data }) => {
                const { eventUpdate } = data!;

                if (!eventUpdate?.id) {
                    return;
                }

                updateResolveMutations({
                    eventId,
                    cache,
                    recipe: ({ eventById }) => {
                        eventById!.resolution_info!.is_resolved = isResolved as boolean;
                        eventById!.resolution_info!.resolved_message = message as string;
                    },
                });
            },
            onQueryUpdated: (observableQuery) => {
                const { options, queryName } = observableQuery;

                if (
                    queryName === 'getEventById' &&
                    options?.variables?.event_id === eventId
                ) {
                    observableQuery.refetch();
                }
            },
        },
    );

export interface UpdateResolveMutationsProps {
  eventId: string;
  recipe: (draft: Draft<GetEventByIdQuery>) => void;
  cache: ApolloCache<any>;
}

const updateResolveMutations = ({
  eventId,
  recipe,
  cache,
}: UpdateResolveMutationsProps) => {
  const variables = {
    event_id: eventId,
    first: firstComments,
  };

  const cachedData = cache.readQuery<
    GetEventByIdQuery,
    GetEventByIdQueryVariables
  >({
    query: GET_EVENT_BY_ID,
    variables,
  })!;

  if (cachedData) {
    const newData = produce(cachedData, recipe);
    cache.writeQuery<GetEventByIdQuery, GetEventByIdQueryVariables>({
      query: GET_EVENT_BY_ID,
      variables,
      data: newData,
    });
  }
};
