import { isArray } from '@chakra-ui/utils';
import { ReactElement, cloneElement } from 'react';
import { createClickKeyDownHandler } from 'src/shared/utils';

import { Box, BoxBasedComponentProps } from '../Box';
import { useDropdownContext } from './DropdownContext';

export type DropdownToggleProps = BoxBasedComponentProps<
  'div',
  {
    children: ReactElement | ReactElement[];
  }
>;

export const DropdownToggle = ({ children, ...props }: DropdownToggleProps) => {
  const { isOpen, onToggle, onOpen, toggleRef, openOnMouseEnter } =
    useDropdownContext();

  const toggleProps = {
    'data-testid': 'dropdown-toggle',
    'aria-expanded': isOpen,
    onClick: onToggle,
    onKeyDown: createClickKeyDownHandler(onToggle),
    onMouseEnter: openOnMouseEnter ? onOpen : undefined,
    ref: toggleRef,
    tabIndex: 0,
  };

  if (isArray(children)) {
    return (
      <Box position="relative" {...props} {...toggleProps}>
        {children}
      </Box>
    );
  }

  return cloneElement(children, toggleProps);
};
