import {
  navbarHeight,
  navEventKey,
  Neighbors,
  useNavbarContext,
} from '@amzn/ring-ui-react-components';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerMenu,
  DrawerMenuItem,
} from 'src/shared/components/Drawer';
import {
  helpCenterNeighboprsUrl,
  hrefDownloadAppAttribution,
} from 'src/shared/utils';
import { NavbarBase, NavMobileButton } from './NavbarBase';
import { useTranslation } from 'react-i18next';
import { getLoginUrl } from 'src/utils';

const NavbarMobile = () => {
  const { t } = useTranslation(['components.navbar', 'common'], {
    useSuspense: false,
  });
  const { activeKey } = useNavbarContext();

  const isOpen = navEventKey === activeKey;

  return (
    <>
      <NavMobileButton href={getLoginUrl()} as="a" icon="account">
        Login
      </NavMobileButton>
      <Drawer isOpen={isOpen} top={navbarHeight}>
        <DrawerBody>
          <DrawerMenu title="Learn More">
            <DrawerMenuItem
              as="a"
              href={hrefDownloadAppAttribution}
              icon={<Neighbors />}
            >
              {t('get-the-app')}
            </DrawerMenuItem>
            <DrawerMenuItem
              as="a"
              href={hrefDownloadAppAttribution}
              icon="account"
            >
              {t('learn-more')}
            </DrawerMenuItem>
            <DrawerMenuItem
              as="a"
              href={helpCenterNeighboprsUrl}
              icon="question-outline"
            >
              {t('help-center')}
            </DrawerMenuItem>
          </DrawerMenu>
        </DrawerBody>
        <DrawerFooter>
          <DrawerMenu>
            <DrawerMenuItem href={getLoginUrl()} as="a" icon="sign-out">
              {t('login', { ns: 'common' })}
            </DrawerMenuItem>
          </DrawerMenu>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

export const UnauthenticatedNavbarMobile = () => (
  <NavbarBase>
    <NavbarMobile />
  </NavbarBase>
);
