import { MediaItem } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import type { MouseEventHandler } from 'react';
import { EventMediaImage } from 'src/shared/components/EventMediaImage';
import { EventMediaVideo } from 'src/shared/components/EventMediaVideo';
import type { AspectRatioProps } from '../AspectRatio';

export interface EventGalleryMediaItemProps
  extends Pick<AspectRatioProps, 'borderRadius' | 'height' | 'maxHeight'>,
    Pick<MediaItem, 'type'> {
  isFullscreen: boolean;
  onMediaClick?: MouseEventHandler<HTMLDivElement>;
  src: string;
}

export const EventGalleryMediaItem = ({
  isFullscreen,
  onMediaClick,
  src,
  type,
}: EventGalleryMediaItemProps) => {
  const aspectRatioProps = isFullscreen
    ? { height: '100vh', maxHeight: '100vh' }
    : {};

  return type === 'image' ? (
    <EventMediaImage
      cursor="pointer"
      onClick={onMediaClick}
      src={src}
      {...aspectRatioProps}
    />
  ) : (
    <EventMediaVideo src={src} {...aspectRatioProps} />
  );
};
