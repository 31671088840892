import { ReactNode } from 'react';
import { Box, ArrowLeft } from '@amzn/ring-ui-react-components';

interface LoopBannerProps {
  icon?: ReactNode;
  text: String;
  href?: String;
  target?: String;
  clickHandler?: () => void;
  bottomRadius?: number;
}

export const LoopBanner = (props: LoopBannerProps) => {
  const { icon, text, clickHandler, href, target, bottomRadius = 6 } = props;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      cursor="pointer"
      background="white"
      px={4}
      py={2}
      borderBottom="1px solid"
      borderLeft="1px solid"
      borderRight="1px solid"
      borderColor={{ base: 'neutral.100' }}
      borderBottomLeftRadius={bottomRadius}
      borderBottomRightRadius={bottomRadius}
      onClick={clickHandler}
      href={href}
      target={target}
      data-testid="loop-banner"
    >
      <Box textStyle="body-normal-medium">
        {icon}
        {text}
      </Box>
      <Box display="flex" alignSelf="center" ml={{ base: 4, m: 0 }}>
        <ArrowLeft
          boxSize={6}
          color="neutral.300"
          style={{ transform: 'rotate(180deg)' }}
        />
      </Box>
    </Box>
  );
};

export default LoopBanner;
