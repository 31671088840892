import type { EventCategory } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { createContext } from '@chakra-ui/react-utils';
import {
  crimeIncidentCategoryViolent,
  crimeIncidentCategoryNonviolent,
} from 'src/shared/utils/constants';

export const [EventCategoriesProvider, useEventCategories] = createContext<{
  categories: EventCategory[];
  loading: boolean;
}>({
  name: 'EventCategoriesContext',
});

export const useEventCategory = (category: string) => {
  const { categories } = useEventCategories();
  const fallback = useGetFallback(category);

  const target = categories.find(({ id }) => id === category);

  return target ?? fallback;
};

// for each category, filter through its subcategories until a match is found
export const useEventSubCategory = (subcategory: string) => {
  let target: any[] = [];
  const { categories } = useEventCategories();
  categories.forEach(function (category) {
    target = target.concat(
      category.subcategories.filter(function (subcat: any) {
        return subcat.id === subcategory;
      }),
    );
  });

  const fallback = useGetFallback(subcategory);

  return target[0] ?? fallback;
};

const useGetFallback = (category: string) => {
  const { loading } = useEventCategories();

  switch (category) {
    case crimeIncidentCategoryNonviolent:
      return nonViolentCategory;
    case crimeIncidentCategoryViolent:
      return violentCategory;
    default:
      return loading === true ? pendingCategory : null;
  }
};

const pendingCategory = {
  title: 'Pending',
} as EventCategory;

const nonViolentCategory = {
  title: 'Nonviolent',
} as EventCategory;

const violentCategory = {
  title: 'Violent',
} as EventCategory;
