import { forwardRef } from 'react';

import { Box, BoxProps } from '../Box';

export const FormHelperText = forwardRef<HTMLDivElement, BoxProps>(
  ({ id, ...props }, ref) => (
    <Box
      color="muted"
      fontSize={1}
      lineHeight="normal"
      mt={2}
      fontWeight={300}
      {...props}
      id={id ? `${id}-help-text` : undefined}
      ref={ref}
    />
  ),
);

FormHelperText.displayName = 'FormHelperText';
