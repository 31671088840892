import {
  EventMediaAssetInput,
  MediaType,
  GetPinByAddressQuery,
  GetPinByAddressQueryVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import PIN_BY_ADDRESS from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/location/getPinByAddress.graphql';
import { useApolloClient } from '@apollo/client';
import { FileTypeResult, fromBlob } from 'file-type/browser';
import { useCallback, useState } from 'react';
import { useCounter } from 'react-use';
import type { PostingContext } from 'src/components/Posting/postingMachine';
import { logError } from 'src/logs';
import { readFileAsArrayBuffer, uploadFile } from 'src/utils';
import { useEventAssetHandlingUrlsCreateMutation } from './useEventAssetHandlingUrlsCreateMutation';
import { useEventCreateMutation } from './useEventCreateMutation';

const initialProgress = 0;
const initialUploaded = 0;
const initialEventId = '';

export const useCreateEvent = () => {
  const client = useApolloClient();
  const createEvent = useEventCreateMutation();
  const createEventAssetHandlingUrl = useEventAssetHandlingUrlsCreateMutation();

  const [progress, setProgress] = useState(initialProgress);
  const [eventId, setEventId] = useState(initialEventId);
  const [error, setError] = useState(false);

  const [
    uploadedCount,
    { inc: incrementCounter, get: getCounter, reset: resetCounter },
  ] = useCounter(initialUploaded);

  const done = Boolean(eventId);
  const uploadingCount = uploadedCount + 1;

  const reset = useCallback(() => {
    setProgress(initialProgress);
    setEventId(initialEventId);
    resetCounter();
  }, [resetCounter]);

  const uploadAssetsGetCoordsAndCreateEvent = useCallback(
    async ({
      title,
      description,
      media,
      category,
      location,
      disableComments,
      disablePushNotifications,
    }: PostingContext) => {
      try {
        setError(false);
        const mediaInputs = [] as EventMediaAssetInput[];

        for (const item of media) {
          if (item instanceof File) {
            const { ext: extension, mime } = (await fromBlob(
              item,
            )) as FileTypeResult;

            const { data } = await createEventAssetHandlingUrl({
              variables: {
                extension,
              },
            });

            const { eventAssetHandlingUrlsCreate } = data!;
            const { asset_url, upload_url } = eventAssetHandlingUrlsCreate!;

            const body = await readFileAsArrayBuffer(item);

            const currentUploadedCount = getCounter();
            const nextUploadedCount = currentUploadedCount + 1;

            if (currentUploadedCount > 0) {
              setProgress(initialProgress);
            }

            await uploadFile({
              url: upload_url,
              body,
              onProgress: ({ loaded, total }) => {
                const newProgress = Math.round((loaded / total) * 100);

                if (newProgress % 5 === 0) {
                  setProgress(newProgress);
                }
              },
            });

            if (nextUploadedCount < media.length) {
              incrementCounter();
            }

            const type = mime.split('/')[0].toUpperCase() as MediaType;

            mediaInputs.push({
              type,
              url: asset_url,
            });
          } else {
            const media: EventMediaAssetInput = {
              url: item.url,
              type: 'VIDEO',
              ring_video_id: item.id,
            };

            mediaInputs.push(media);
          }
        }

        const { data: pinByAddressData } = await client.query<
          GetPinByAddressQuery,
          GetPinByAddressQueryVariables
        >({
          query: PIN_BY_ADDRESS,
          variables: {
            address: location!.value,
          },
        });

        const { pinByAddress } = pinByAddressData!;
        const { latitude, longitude } = pinByAddress!;

        const { data: createEventData } = await createEvent({
          variables: {
            title,
            description,
            category: category!.id,
            latitude,
            longitude,
            media_assets: mediaInputs,
            disable_comments: disableComments,
            disable_push_notifications: disablePushNotifications,
          },
        });

        const { eventCreate } = createEventData!;

        if (eventCreate) {
          const { id } = eventCreate;

          setEventId(id);
        } else {
          setError(true);
        }
      } catch (error) {
        const e = error as Error;

        setError(true);
        logError(e.message);
      }
    },
    [
      client,
      createEvent,
      createEventAssetHandlingUrl,
      incrementCounter,
      getCounter,
    ],
  );

  return [
    uploadAssetsGetCoordsAndCreateEvent,
    { done, eventId, progress, uploadedCount, uploadingCount, error },
    reset,
  ] as const;
};

export type UseCreateEventReturn = ReturnType<typeof useCreateEvent>;
