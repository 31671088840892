import { v4 as uuidv4 } from 'uuid';
import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import { useHasWebViewUserAgent, useUserAgentParser } from 'src/hooks';
import { eventStreamService } from 'src/shared/services';
import { useAuth } from 'src/context';
import i18n from 'src/i18n';
import { getUserIDHmacToken } from 'src/auth';

const TIME_1_DAY = 1; // https://github.com/carhartl/jquery-cookie#cookie-options
const EVENT_STREAM_UUID_ID = 'event-stream-uuid';
export type EventNameType = 'Screen.View' | 'Item.Click';
const EVENT_VERSION = '1.0.0';
const SCHEMA_VERSION = '1.0.0';

export const useEventStream = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [err, setErr] = useState<any>();
  const UAParser = useUserAgentParser();
  const isWebView = useHasWebViewUserAgent();
  const eventStreamUuid = Cookies.get(EVENT_STREAM_UUID_ID) || '';
  const { userId, isAuthenticated, isPending } = useAuth();
  const { language } = i18n;

  if (Cookies.get(EVENT_STREAM_UUID_ID) === undefined) {
    // beginning of session, set expiration date to be midnight
    var expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + TIME_1_DAY);
    expirationDate.setHours(0, 0, 0, 0);

    var cookieDomain =
      window.location.hostname.indexOf('ring.com') < 0
        ? window.location.hostname
        : '.ring.com';
    Cookies.set(EVENT_STREAM_UUID_ID, uuidv4(), {
      expires: expirationDate,
      path: '/',
      domain: cookieDomain,
    });
  }

  const getAnonymousEvent = useCallback(
    (eventName: EventNameType, tags: string[], properties: any) => {
      const today = new Date();
      const OS = UAParser?.getOS();
      const userAgent = UAParser?.getUA();

      return {
        eventName,
        eventVersion: EVENT_VERSION,
        schemaVersion: SCHEMA_VERSION,
        ctx: {
          anonymousId: eventStreamUuid,
          tags: [
            ...tags,
            ...((isAuthenticated &&
              !isPending && ['RingToken=' + getUserIDHmacToken()]) ||
              []),
            ...((isWebView && ['webview']) || []),
          ],
          userAgent,
        },
        env: {
          region: 'external',
          timezone: 'UTC+00:00',
        },
        meta: {
          messageId: uuidv4(),
          seq: 1,
          sentDateTime: today.toISOString(),
          timestamp: today.toISOString(),
        },
        sys: {
          clientDevice: {
            name: OS?.name,
            version: OS?.version,
            language,
          },
        },
        properties: {
          ...properties,
          ...(() => {
            if (eventName === 'Screen.View' || eventName === 'Item.Click') {
              return {
                brand: properties.brand || 'neighbors',
              };
            } else return {};
          })(),
        },
      };
    },
    [
      UAParser,
      isWebView,
      eventStreamUuid,
      isAuthenticated,
      userId,
      isPending,
      language,
    ],
  );

  const dispatchEvent = useCallback(
    async (eventName: EventNameType, tags: string[], properties?: any) => {
      const event = getAnonymousEvent(eventName, tags, properties);
      try {
        setFetching(true);
        await eventStreamService.sendEvent(event);
        setFetching(false);
      } catch (err) {
        setErr(err);
        setFetching(false);
      }
    },
    [getAnonymousEvent],
  );

  return { dispatchEvent, fetching, err };
};
