import type { CSSObject } from 'styled-components';
import type { StyledSystemProps } from './styledSystem';

export const defaults = ({ flex }: StyledSystemProps) =>
  ({
    boxSizing: 'border-box',
    flex: flex === 1 ? '1 1 auto' : flex, // IE fix for flex: 1
    margin: 0,
    minWidth: 0,
  } as CSSObject);
