import { useIsMobile } from 'src/shared/hooks';

import { UnauthenticatedNavbarMobile } from './UnauthenticatedNavbarMobile';
import { UnauthenticatedNavbarDesktop } from './UnauthenticatedNavbarDesktop';

export const UnauthenticatedNavbar = () => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <UnauthenticatedNavbarMobile />
  ) : (
    <UnauthenticatedNavbarDesktop />
  );
};
