const NODE_ENV = process.env['NODE_ENV'];

export const isLocalBuild = NODE_ENV === 'development';
export const isDeployedBuild = NODE_ENV === 'production';

const { hostname } = window.location;

export const isProd = hostname === 'neighbors.ring.com';
export const isQA = hostname === 'neighbors.qa.ring.com';
export const isDev = hostname === 'neighbors.dev.ring.com';
export const isLocalNpss = hostname === 'localnpss.dev.ring.com';
export const isLocalNeighbors = hostname === 'localneighbors.dev.ring.com';
