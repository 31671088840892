import { DENIAL_PREFIX } from './shared/utils';

const event = 'event';

export const WEB_VIEW_PREFIX = 'wv';

export const AppRoutes = {
  BestOfRing: '/best-of-ring',
  BestOfRingVideo: '/best-of-ring/:id_video(\\w+)',
  BetterNeighborhoods: '/better-neighborhoods',
  Deals: '/deals',
  Discover: '/discover',
  EmergencyInfo: '/emergency-info/:id',
  EmergencyInfoMap: '/emergency-info/:id/map',
  EmergencyInfoTopic: '/emergency-info/:id/topic/:tid',
  EmergencyInfoTopics: '/emergency-info/:id/topics',
  EmergencyInfoUpdates: '/emergency-info/:id/updates',
  EventCreate: `/${event}/create`,
  EventDetailOrSharePage: `/(${event}|a|c|d|e|n)/:id(\\w+)`,
  Feed: '/',
  Login: '/login',
  Logout: '/logout',
  MyPosts: '/settings/my-posts',
  PetTag: '/qr/:code',
  PetProfile: `/pet-profile/:id`,
  PetTagFlyer: `/pet-flyer/:id`,
  PetMarketingPage: '/nav/setup_tag',
  Terms: '/terms',
  NotFound: '/404',
  DeniedPost: '/' + DENIAL_PREFIX + '/:denial_category',
  EmailSettings: '/settings/email',
} as const;

export const getEventDetailPath = (id: string) => `/${event}/${id}`;
